import { Component } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
	selector: 'app-tournaments',
	templateUrl: './tournaments.component.html',
	styleUrls: ['./tournaments.component.css'],
	animations: [
		trigger('didyouknow', [
			state('Collapse', style({ width: '0%', transform: 'translateX(0)' })),
			state('Expand', style({ width: '100%', transform: 'translateX(0)' })),
			transition('Collapse <=> Expand', animate(1000)),
		]),
		trigger('benefits', [
			state('Collapse', style({ width: '100%', transform: 'translateX(0)' })),
			state('Expand', style({ width: '0%', transform: 'translateX(0)' })),
			transition('Collapse <=> Expand', animate(1000)),
		]),
	],
})
export class TournamentsComponent {
	public animationState = 'Expand';
	constructor(public appService: AppService) {}

	public onAnimate() {
		this.animationState == 'Collapse' ? (this.animationState = 'Expand') : (this.animationState = 'Collapse');
	}
}
