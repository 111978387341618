<h1>Personal Information</h1>
<p>
	We gather various types of Personal Information from our users, as explained in more detail below, and we use this
	Personal Information internally in connection with the usage of this website, including:
</p>
<ul>
	<li>to personalize and improve the website;</li>
	<li>to allow you to set up a user account and profile;</li>
	<li>to contact you;</li>
	<li>to fulfill your requests for certain products and services;</li>
	<li>to provide and improve the website; and to analyze how you use the website.</li>
</ul>
<p>
	In certain cases, we may also share some Personal Information with third parties, but only as described below. This
	Privacy Policy deals with our use of your own Personal Information, and not Third Party Personal Information that you
	may provide in connection with these Services.
</p>

<h1>Under the Age of 18</h1>
<p>
	We do not knowingly collect, solicit, or maintain Personal Information from anyone under the age of 18 or knowingly
	allow such persons to register for our Services via this website. If you are under 18, please do not send any Personal
	Information about yourself (such as your name, address, telephone number, or email address) to us. No one under age 18
	is allowed to provide any Personal Information to or on the Services. In the event that we learn that we have
	collected Personal Information from a child under age 18 without verification of parental consent, we will use
	commercially reasonable efforts to delete that information from our database. Please contact us at info@yongsa.net if
	you have any concerns.
</p>
<h1>Third-party Use of Cookies and Other Tracking Technologies</h1>
<p>
	We use Google Analytics and Facebook Pixel to help us understand the use of our Services. This service collects the
	information sent by your browser as part of a web page request, including cookies and your IP address, and their use
	of it is governed by their privacy policy. The financial information connected to payments may be collected or stored
	by a third-party payment processor and is subject to their terms and policies.
</p>
