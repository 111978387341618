<app-cover *ngIf="page==='/google'"></app-cover>
<div>
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar class="google-image"></div>
      <mat-card-title>Google Profile</mat-card-title>
      <mat-card-subtitle>Just the Facts</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>Visit our Google Business Profile which is a great place to get all the facts about Yongsa. While you are there, please read our reviews, find our business hours, get directions,
      or use the phone number to give us a call us. Most importantly, please share it with a friend. As the saying goes, "friends that kick together; stick together!" </mat-card-content>
    <mat-card-actions style="margin:auto; text-align: center;">
      <button mat-raised-button color="primary" (click)="appService.openUrl('home')">Google Business</button><br>
    </mat-card-actions>
  </mat-card>
</div>