import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Contact } from 'src/app/models/app.model';
import { AppService } from '../../../../services/app.service';
import { FirestoreService } from '../../../../services/firestore.service';

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.css'],
})
export class AccountComponent {
	// These properties are private because they are only used in the component
	private componentError!: any;

	// These properties are public because they are used in the view
	@Output() account = new EventEmitter<number>();
	public FormGroup!: FormGroup;
	public givenContact: Contact;

	constructor(private firestoreService: FirestoreService, public appService: AppService) {
		this.givenContact = {
			addresses: { city: '', postalCode: '', region: '', streetAddress: '' },
			mas: {
				accountSettings: {
					billing: 'true',
					gender: '',
					member: 'false',
					status: 'Trial',
				},
			},
			names: {
				familyName: '',
				givenName: '',
			},
			phoneNumbers: { value: '' },
		};
	}

	public checkExistingEmail(): void {}

	public onAddAccount(f: any): void {
		if (this.appService.hasExistingEmail === true) {
			this.appService.billingAccount!.emailAddresses!.value = f.value.email;
			this.appService.billingAccount.names = f.value.names;
			this.appService.billingAccount.mas!.accountSettings!.gender = f.value.gender;
			this.appService.billingAccount.mas!.accountSettings!.status = 'Trial';
			this.appService.billingAccount.mas!.accountSettings!.billing = 'true';
			this.appService.billingAccount.addresses = f.value.addresses;
			this.appService.billingAccount.phoneNumbers = { value: f.value.phone };
			this.firestoreService
				.mergeDocument('mas-accounts', this.appService.billingAccountId, this.appService.billingAccount)
				.then()
				.catch(error => {
					this.componentError = error;
					console.log(error.message);
				});
		} else {
			this.appService.billingAccount = {} as Contact;
			this.appService.billingAccount.emailAddresses = { value: f.value.email };
			this.appService.billingAccount.names = f.value.names;
			this.appService.billingAccount.mas = this.givenContact.mas;
			this.appService.billingAccount.mas!.accountSettings!.gender = f.value.gender;
			this.appService.billingAccount.addresses = f.value.addresses;
			this.appService.billingAccount.phoneNumbers = { value: f.value.phone };
			this.firestoreService
				.addDocument('mas-accounts', this.appService.billingAccount)
				.then(result => {
					this.appService.billingAccountId = result;
				})
				.catch(error => {
					this.componentError = error;
					console.log(error.message);
				});
		}
		if (typeof this.componentError === 'undefined') {
			this.appService.canEditAccount = false;
			this.appService.canEditStudent = true;
			this.account.emit(2);
		} else {
			console.log(this.componentError);
		}
	}
}
