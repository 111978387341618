import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';
@Component({
	selector: 'app-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls: ['./sidenav.component.css'],
})
export class SidenavComponent implements OnInit {
	// These properties are public because they are used in the view
	@ViewChild('drawer', { static: true }) drawer!: MatSidenav;
	private authUser!: string;
	public isLoggedIn!: boolean;
	public initialSidebar = true;
	public sideItem: string | null = null;

	public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
		map(result => {
			return result.matches;
		}),
		shareReplay()
	);

	constructor(private breakpointObserver: BreakpointObserver, public authService: AuthService, public router: Router) {}

	ngOnInit(): void {
		this.getAuthUser();
	}

	async getAuthUser() {
		this.authUser = await this.authService.user$.toPromise().then(user => user?.email as string);
	}

	public onHamburgerClick(): void {
		if (this.initialSidebar) {
			this.initialSidebar = false;
		}
		this.drawer.toggle();
	}

	public expandSideItemClick(item: string): void {
		if (this.sideItem) {
			this.sideItem = null;
		} else {
			this.sideItem = item;
		}
	}

	public onSideItemClick(route: any): void {
		this.drawer.toggle();
		this.router.navigate([route]);
	}

	public onLogoutClick(): void {
		this.authService.logout();
		this.router.navigate(['/login']);
	}
}
