<div id="cover">
    <mat-card>
        <img mat-card-image src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2Fninjanight.png?alt=media&token=0e7f1091-830c-4e18-98a2-685c732092fe" alt="Fight Dirty">
    </mat-card>
</div>
<div id="fightdirty">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="ninja-image"></div>
            <mat-card-title>Ninja Night</mat-card-title>
        </mat-card-header>
        <mat-card-content>For just $10 to $20, Ninja Night is your "child's night in" and the "parents' night out." Kids enjoy three hours of self-defense, games, pizza for dinner, and an
            age-appropriate movie with popcorn. </mat-card-content>
        <mat-card-content>And the parents? You get to enjoy dinner out or that nap you've been thinking about all day. It's up to you! </mat-card-content>
        <mat-card-content>We limit this event to 20 children. </mat-card-content>
        <mat-card-content>With the losing of Covid-19 restrictions, we have resumed our Ninja Nights. However, at this time, students can only sign up for the event in the studio. In the coming weeks,
            we will resume our online signup.</mat-card-content>
        <mat-card-actions style="margin:auto; text-align: center;">
            <button mat-raised-button color="primary" (click)="appService.openUrl('ninjanight')" [disabled]="true">Sign Up</button>
        </mat-card-actions>
    </mat-card>
</div>