<div id="cover">
    <mat-card>
        <img mat-card-image src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2Fadulthealthbenefits.png?alt=media&token=1d274f6a-3fd1-4ea0-ac8a-e4fe07cee9b8" alt="Taekwondo">
    </mat-card>
</div>
<div id="cma">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="explore-image"></div>
            <mat-card-title>You Can Start Your Martial Arts Journey Today!</mat-card-title>
        </mat-card-header>
        <!-- mat-card-content>You are never too old to start a new journey. Our adult taekwondo classes start at just
            $55!<br>
        </mat-card-content>
        <mat-card-actions >
            <button mat-raised-button color="primary" routerLink="/trial">Adult Taekwondo</button><br>
        </mat-card-actions -->
    </mat-card>
</div>
<mat-divider></mat-divider>
<div id="contracts">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="contract-image"></div>
            <mat-card-title>No Contract and No Testing Fee!</mat-card-title>
        </mat-card-header>
        <mat-card-content>Unlike most other martial art schools, we do not require a contract. We also do not charge a color belt testing fee. </mat-card-content>
    </mat-card>
</div>
<mat-divider></mat-divider>
<div id="">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="health-image"></div>
            <mat-card-title>Adult Health and Fitness Benefits</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <article>
                <span></span>
                <h5>#1 Increased Mobility</h5>
                <div>Martial arts is the perfect activity for improving agility through muscle and joint training! Once you make ATA workouts a part of your regular routine, you will find that
                    mobility makes its way into other parts of your life, too. The secret to keep moving is the start moving! <h5>#2 Strength & Power</h5>
                </div>
                <div>To be an effective martial artist, you must be strong and powerful. This is perfect, because ATA workouts are designed to improve these attributes in students! Whether you need to
                    properly defend yourself or perform under pressure, your training will help you maximize your explosiveness. No matter what opponent, strength and power will always be to the
                    advantage of the person who possesses it. </div>
                <div>
                    <h5>#3 Improved Flexibility</h5> Flexibility is a vital part of martial arts-- but it’s about way more than just those high level kicks! Having improved flexibility allows for more
                    mobility which also helps with injury prevention. Being more flexible is also important when you’re trying to evade an opponent. Stretch yourself to new limits with ATA training!
                </div>
                <div>
                    <h5>#4 Improve Your Coordination</h5> From an early age, ATA students begin seeing the benefits of increased coordination with their training. Being more aware of your body’s
                    positioning and having improved focus allows martial artists of all ages to develop a secure and steady base for performance.
                </div>
                <div>
                    <h5>#5 Lowered Blood Pressure & Heart Rate</h5> Martial artists work a lot of muscles in their training, and the heart is one that reaps many of the benefits! The cardiovascular
                    workouts in ATA require a fitness level that will help lower blood pressure and improve your heart rate. Getting heart healthy has never been more fun!
                </div>
                <div>
                    <h5>#6 Mental Concentration</h5> The key to being a well-rounded martial artist is training your body and mind. Life skills such as respect, focus and discipline help establish a
                    mental concentration that is beneficial is all areas of human growth. Regularly practicing memorization and acuity helps with performance both on and off the mats!
                </div>
                <div>
                    <h5>#7 Stress Relief</h5> If you are looking for an activity to help you become more centered, self-aware and reduce your stress, then martial arts could be your answer! Learning
                    how to remain calm under pressure, quick decision making and managing effective communication are all part of the ATA experience. This “way of life” will guide you in improving
                    your self-management.
                </div>
            </article>
        </mat-card-content>
    </mat-card>
</div>
<mat-divider></mat-divider>
<div id="schedule">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="calendar-image"></div>
            <mat-card-title>Class Schedule</mat-card-title>
        </mat-card-header>
        <mat-card-content> Click on the button below to view a complete schedule of Yongsa classes. </mat-card-content>
        <mat-card-actions style="margin:auto; text-align: center;">
            <button mat-raised-button color="primary" routerLink="/classes">Schedule</button><br>
        </mat-card-actions>
    </mat-card>
</div>