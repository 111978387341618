import { Component, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../layout/badges/dialog/dialog.component';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-karateforkids',
	templateUrl: './karateforkids.component.html',
	styleUrls: ['./karateforkids.component.css'],
})
export class KarateForKidsComponent implements OnDestroy {
	private contractMessageSubscription!: Subscription;
	private testingMessageSubscription!: Subscription;
	private testingStyleMessageSubscription!: Subscription;

	constructor(public appService: AppService, public dialog: MatDialog) {}

	/***********************************************************************************************************************
	 * @name openContractMessage
	 * @memberof MissionComponent
	 * @author Greg Harner
	 * @description opens a dialog page this share mission critical information with customers
	 * @return void
	 * @link
	 ***********************************************************************************************************************/
	public openContractMessage(): void {
		this.appService.dialogMessage = `Yongsa believes requiring our customers to sign a contract is contrary to our 
		mission of "making champions." We want our students to train with us because they have an extraordinary championship
		attitude and not because they are stuck in a contract.`;
		const dialogRef = this.dialog.open(DialogComponent, {
			height: '400px',
			width: '600px',
		});
		this.contractMessageSubscription = dialogRef.afterClosed().subscribe();
	}

	/***********************************************************************************************************************
	 * @name openTestingMessage
	 * @memberof MissionComponent
	 * @author Greg Harner
	 * @description opens a dialog page this share mission critical information with customers
	 * @return void
	 * @link
	 ***********************************************************************************************************************/
	public openTestingMessage(): void {
		this.appService.dialogMessage = `When a martial art school ties revenue to testing eligibility, it creates a 
		financial incentive to test students insufficiently prepared for the next rank.`;
		const dialogRef = this.dialog.open(DialogComponent, {
			height: '400px',
			width: '600px',
		});
		this.testingMessageSubscription = dialogRef.afterClosed().subscribe();
	}

	/***********************************************************************************************************************
	 * @name openTestingMessage
	 * @memberof MissionComponent
	 * @author Greg Harner
	 * @description opens a dialog page this share mission critical information with customers
	 * @return void
	 * @link
	 ***********************************************************************************************************************/
	public openTestingStyle(): void {
		this.appService.dialogMessage = `Your first tournament can be intimidating, which can decrease your performance. 
		We find Yongsa students are significantly more prepared for competition because of our testing policy.`;
		const dialogRef = this.dialog.open(DialogComponent, {
			height: '400px',
			width: '600px',
		});
		this.testingStyleMessageSubscription = dialogRef.afterClosed().subscribe();
	}
	ngOnDestroy(): void {
		if (this.contractMessageSubscription) this.contractMessageSubscription.unsubscribe();
		if (this.testingMessageSubscription) this.testingMessageSubscription.unsubscribe();
		if (this.testingStyleMessageSubscription) this.testingStyleMessageSubscription.unsubscribe();
	}
}
