<div>
    <mat-card>
        <img mat-card-image src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/lifeskills%2Fcommunication.png?alt=media&token=2f7e5ba4-13cf-47db-9899-eb01ca86a3d4"
            alt="Partner In Education">
    </mat-card>
</div>
<div>
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="school-image"></div>
            <mat-card-title>Champions for the Education!</mat-card-title>
        </mat-card-header>
        <mat-card-content>All of the owners of Yongsa Martial Arts are not only former United States Marines but also all college graduates. We understand the importance of education. We also
            understand that our public schools operate on a tight fixed budget.</mat-card-content>
        <mat-card-content>Through our Partnership in Education program, the staff at Yongsa has contributed 115 volunteer hours and donated $15,097 to Smyrna area public schools. </mat-card-content>
        <mat-card-content>You can learn more about our Partnership in Education program at the following websites: </mat-card-content>
        <mat-card-actions style="margin:auto; text-align: center;">
            <button mat-raised-button color="primary" (click)="appService.openUrl('campbell')">Campbell Middle School</button>
            <button mat-raised-button color="primary" (click)="appService.openUrl('nickajack')">Nickajack Elementary School</button>
            <button mat-raised-button color="primary" (click)="appService.openUrl('teasley')">Teasley Elementary School</button><br>
        </mat-card-actions>
        <br>
    </mat-card>
</div>