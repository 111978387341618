<mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)"
    animationDuration="2000ms">
    <mat-tab *ngFor="let label of tabLabels; let index = index" [label]="label">
        <app-getstarted *ngIf="label==='Getting Started'" (start)="childComponentOutput($event)">
        </app-getstarted>
        <app-account *ngIf="label==='Billing Account'" (account)="childComponentOutput($event)"></app-account>
        <app-student *ngIf="label==='Student Account'" (student)="childComponentOutput($event)"></app-student>
        <app-pay *ngIf="label==='Purchase'" (payment)="childComponentOutput($event)"></app-pay>
        <app-orientation *ngIf="label==='Orientation'"></app-orientation>
    </mat-tab>
</mat-tab-group>