<div id="cover">
  <mat-card>
    <img mat-card-image src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/lifeskills%2Fself-esteem.png?alt=media&token=0a0aef1a-c71d-4e5f-8108-9cb526c8cc58" alt="Taekwondo">
  </mat-card>
</div>
<div id="cma">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar class="explore-image"></div>
      <mat-card-title>Making Your Child A Champion!</mat-card-title>
    </mat-card-header>
    <mat-card-content>Research shows that parents who raise children that become thriving adults teach their kids to have high expectations. At Yongsa, we set high expectations for our students. That
      is why our mission is "making champions." For some people, the possibility of failure when facing such high expectations is daunting. We understand this, and we know that teaching kids how to
      deal with failure is a necessary part of making a champion. Our instructors and staff will be there to help your child celebrate their victories and, just as importantly, to lift them when they
      fail. </mat-card-content>
  </mat-card>
</div>
<mat-divider></mat-divider>
<div id="prices">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar class="payment-image"></div>
      <mat-card-title>Transparent Pricing</mat-card-title>
    </mat-card-header>
    <mat-card-content>We offer no haggle transparent prices. Our base tuition is $145 per month.</mat-card-content>
  </mat-card>
  <mat-divider></mat-divider>
  <div id="contracts">
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar class="contract-image"></div>
        <mat-card-title>No Contract<a (click)="openContractMessage()">
            <mat-icon>info</mat-icon>
          </a></mat-card-title>
      </mat-card-header>
      <mat-card-content>For us, it is simple. You should attend class because you want to learn martial arts and not because you are stuck in a contract. Our policy of no contracts puts the burden on
        us as instructors to make sure that, during each class, we give you a reason to come back!</mat-card-content>
    </mat-card>
    <mat-divider></mat-divider>
    <div id="beltfees">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="hiddenfee-image"></div>
          <mat-card-title>No Testing Fee!<a (click)="openTestingMessage()">
              <mat-icon>info</mat-icon>
            </a></mat-card-title>
        </mat-card-header>
        <mat-card-content>Karate schools know that monthly tuition cost is a significant factor in deciding where to take classes. To hide the real cost of classes, many schools try to hide it in fees
          and purchases. A perfect example of this is belt testing fees. At Yongsa, we believe you earn your belt. You don't buy it.</mat-card-content>
      </mat-card>
    </div>
    <mat-divider></mat-divider>
    <div id="equipment">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="money-image"></div>
          <mat-card-title>Reasonable Equipment Cost!</mat-card-title>
        </mat-card-header>
        <mat-card-content>A net saving on month tuition becomes a net loss when you pay four times more for the required equipment and uniforms. At Yongsa, you will always pay a reasonable price for
          equipment and uniforms. We also buy back gently used gear and resell it at a deep discount. Most of the gear needed to train is purchase during enrollment at a price of $399.00 which also
          includes the first months tuition.</mat-card-content>
      </mat-card>
    </div>
    <mat-divider></mat-divider>
    <div id="curriculum">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="school-image"></div>
          <mat-card-title>Curriculum</mat-card-title>
        </mat-card-header>
        <mat-card-content>Taekwondo training involves a variety of techniques, to include punching, kicking, dodging, jumping, parrying and blocking. Taekwondo also focuses on sparring and learning
          formal patterns of movement called forms. </mat-card-content>
      </mat-card>
    </div>
    <mat-divider></mat-divider>
    <div id="Competition">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="medal-image"></div>
          <mat-card-title>Competition</mat-card-title>
        </mat-card-header>
        <mat-card-content>Many people start taking taekwondo for self-defense. However, it is important to remember that it is also a sport. In fact, it is currently one of only two martial arts
          allowed in the Olympic Games. Yongsa students are encouraged to participate in locally hosted ATA tournaments, and we are proud to be the about of several <a href=""
            routerLink="/champs">Georgia State Champions</a> and <a href="" routerLink="/champs">Southeast District Champions</a>
        </mat-card-content>
        <mat-card-content>To adequately prepare our students for tournament competitions, we conduct all of our color belt testings in the form of a tournament. There is no other school that we know
          of that has this policy.<a (click)="openTestingStyle()">
            <mat-icon>info</mat-icon>
          </a>
        </mat-card-content>
      </mat-card>
    </div>
    <mat-divider></mat-divider>
    <div id="schedule">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="calendar-image"></div>
          <mat-card-title>Class Schedule</mat-card-title>
        </mat-card-header>
        <mat-card-content>Click on the button below to view a complete schedule of Yongsa classes. </mat-card-content>
        <mat-card-actions style="margin:auto; text-align: center;">
          <button mat-raised-button color="primary" routerLink="/classes">Schedule</button><br>
        </mat-card-actions>
      </mat-card>
    </div>
    <mat-divider></mat-divider>
    <div id="youtube">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="youtube-image"></div>
          <mat-card-title>Youtube Testimonial</mat-card-title>
        </mat-card-header>
        <mat-card-content id="youtube-content">
          <iframe width="700" height="401" src="https://www.youtube.com/embed/dKHeXC_2bHE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </mat-card-content>
      </mat-card>
    </div>