import { Component, AfterViewChecked, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../../services/app.service';
import { FirestoreService } from '../../../../services/firestore.service';
import { firebase } from '../../../../../environments/environment';

declare let paypal: any;
@Component({
	selector: 'app-pay',
	templateUrl: './pay.component.html',
	styleUrls: ['./pay.component.css'],
})
export class PayComponent implements AfterViewChecked {
	// These properties are private because they are only used in the component
	@Output() payment = new EventEmitter<number>();
	private scriptedAdded = false;

	private paypalClientCfg = {
		style: {
			shape: 'pill',
			color: 'gold',
			layout: 'vertical',
			label: 'paypal',
		},
		createOrder: (
			_data: any,
			actions: { order: { create: (arg0: { purchase_units: { amount: { value: string } }[] }) => any } }
		) => {
			return actions.order.create({
				purchase_units: [
					{
						amount: {
							value: '55.00',
						},
					},
				],
			});
		},
		onError: (error: any) => {
			console.log(error.message);
			this.handleError(error.message);
		},
		onApprove: (_data: any, actions: { order: { capture: () => Promise<any> } }) => {
			return actions.order.capture().then((details: { payer: any }) => {
				this.addPaypalOrder(details.payer);
				this.handleSuccess();
			});
		},
	};

	constructor(private firestoreService: FirestoreService, public appService: AppService, private router: Router) {}

	/***********************************************************************************************************************
	 * @name ngAfterViewChecked
	 * @memberof PayComponent
	 * @author Greg Harner
	 * @description checks to see if Paypal script has been added to DOM and if it has not been added then it calls the
	 * function to add it once. All other lifecycle checks on the view do nothing.
	 * @function addPaypalScript()
	 * @return void
	 * @link
	 ***********************************************************************************************************************/
	ngAfterViewChecked(): void {
		if (this.appService.canMakePayment && !this.scriptedAdded) {
			this.addPaypalScript()
				.then(() => {
					paypal.Buttons(this.paypalClientCfg).render('#paypal-button-container');
				})
				.catch(error => console.log(error.message));
		}
	}

	/***********************************************************************************************************************
	 * @name ngAfterViewChecked
	 * @memberof PayComponent
	 * @author Greg Harner
	 * @description adds the Paypal script to the DOM
	 * @return {promise<any>}
	 * @link
	 ***********************************************************************************************************************/
	public addPaypalScript(): Promise<any> {
		this.scriptedAdded = true;
		return new Promise((resolve, _reject) => {
			const scripttagElement = document.createElement('script');
			scripttagElement.src = 'https://www.paypal.com/sdk/js?client-id=' + firebase.PayPal_Client_Id + '&currency=USD';
			scripttagElement.onload = resolve;
			document.body.appendChild(scripttagElement);
		});
	}

	/***********************************************************************************************************************
	 * @name addPaypalOrder
	 * @memberof PayComponent
	 * @author Greg Harner
	 * @description called by the html page and adds order data to firestore database
	 * @argument {paypal: any}
	 * @method firestoreService.addDocument()
	 * @return void
	 * @link
	 ***********************************************************************************************************************/
	public addPaypalOrder(paypal: any): void {
		const paypalOrder = { paypal };
		paypal.billingAccountId = this.appService.billingAccountId;
		this.firestoreService.addDocument('mas-orders', paypalOrder).catch(error => console.log(error.message));
	}

	/***********************************************************************************************************************
	 * @name handleError
	 * @memberof PayComponent
	 * @author Greg Harner
	 * @description handles paypal script errors
	 * @argument {error}
	 * @method router.navigate(['/failure'])
	 * @return void
	 * @link
	 ***********************************************************************************************************************/
	public handleError(error: any): void {
		console.log(error.message);
		this.router.navigate(['/failure']);
	}

	/***********************************************************************************************************************
	 * @name handleSuccess
	 * @memberof PayComponent
	 * @author Greg Harner
	 * @description handles paypal script success
	 * @method router.navigate(['/success'])
	 * @return void
	 * @link
	 ***********************************************************************************************************************/
	public handleSuccess(): void {
		this.payment.emit(4);
	}
}
