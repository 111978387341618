<mat-card class="material-card mt-1">
    <mat-card-header>
        <div mat-card-avatar class="info-image"></div>
        <mat-card-title>Let's get your orientation scheduled</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>Taekwondo is a sport. Like all sports, there is some risk of injury. For this reason, the participant and a guardian, if the participant is a minor, need to attend an orientation
            (approximately 30 minutes). At the orientation, we will explain any risk, and you will need to sign a sports waiver. Additionally, a member of our staff will size the participant for a
            uniform.</p>
        <p>We are scheduling all orientations by phone (678) 381-8305 or email info@yongsa.net.</p>
    </mat-card-content>
</mat-card>