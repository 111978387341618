<div id="cover">
    <mat-card>
        <img mat-card-image
            src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2Ftigers.png?alt=media&token=64c0ed14-8f6b-4f5a-a151-b0743eeaf4a6"
            alt="Tigers Stories">
    </mat-card>
</div>
<div id="stories">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="book-image"></div>
            <mat-card-title>Tigers Stories</mat-card-title>
            <mat-card-subtitle>Playlist of the nine Tigers stories!</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <iframe src="https://www.youtube.com/embed/videoseries?list=PL7q-X4OkBZCWF52_3lIx9L_qvOnIagUxF"
                frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        </mat-card-content>
    </mat-card>
</div>