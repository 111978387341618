import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { Contribution } from '../../../models/app.model';
import { FirestoreService } from '../../../services/firestore.service';
@Component({
	selector: 'app-pie',
	templateUrl: './pie.component.html',
	styleUrls: ['./pie.component.css'],
})
export class PieComponent implements OnInit {
	// These properties are public because they are used in the view
	//public dollarsContributed: number;
	//public hoursContributed: number;

	constructor(private firestoreService: FirestoreService, public appService: AppService) {}

	ngOnInit() {
		//this.getImpact();
	}

	// change this to a functions api call
	/* 	getImpact() {
		this.firestoreService.getContributionDocumentsAsObservable('pie-contributions').subscribe(result => {
			let totalDollars = 0;
			let totalHours = 0;
			result.forEach((doc: Contribution) => {
				totalDollars = totalDollars + doc.dollars;
				totalHours = totalHours + doc.hours;
			});
			this.dollarsContributed = totalDollars;
			this.hoursContributed = totalHours;
		});
	} */
}
