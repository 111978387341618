import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-reject',
	templateUrl: './reject.component.html',
	styleUrls: ['./reject.component.css'],
})
export class RejectComponent implements OnInit {
	public reason!: string;

	constructor(private router: Router) {
		if (this.router.getCurrentNavigation()!.extras.state) {
			this.reason = this.router.getCurrentNavigation()!.extras.state!.reason;
		}
	}

	ngOnInit() {}
}
