<div *ngIf="reason==null">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="error-image"></div>
            <mat-card-title>Something went wrong</mat-card-title>
        </mat-card-header>
        <mat-card-content>Please phone us at 678-381-8305 or email us at info@yongsa.net.<br>
        </mat-card-content>
    </mat-card>
</div>
<div *ngIf="reason==='isNotTrial'">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="error-image"></div>
            <mat-card-title>Trial Offer Restrictions</mat-card-title>
        </mat-card-header>
        <mat-card-content>Based on the email address used, it appears that someone in your family has previously tried
            classes at Yongsa. If someone else in your family is now interested in taking lessons, please phone us at
            678-381-8305 or email us at info@yongsa.net.<br>
        </mat-card-content>
    </mat-card>
</div>
<div *ngIf="reason==='isUnderFour'">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="error-image"></div>
            <mat-card-title>Age Restriction</mat-card-title>
        </mat-card-header>
        <mat-card-content>Students at Yongsa must be at least 4 years old.<br>
        </mat-card-content>
    </mat-card>
</div>