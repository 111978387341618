<div id="cover">
    <mat-card>
        <img mat-card-image src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2Fmichaelharner.png?alt=media&token=6d5e8b26-a7fa-46ed-a7ea-dcb147e9f039" alt="Michael Harner">
    </mat-card>
</div>
<mat-divider></mat-divider>
<div id="mharner">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="profile-image"></div>
            <mat-card-title>Michael Harner</mat-card-title>
        </mat-card-header>
        <mat-card-content>Michael is the Chief Instructor at Yongsa and is responsible for developing and implementing the school's curriculum. Michael started his martial arts training at age five
            years in Memphis, TN. When he moved with his family to Atlanta, he took a twenty-year break from his training. However, during that time, Michael graduated from Campbell High School with
            an International Baccalaureate diploma. At 18, he joined the Marine Corps, earning the rank of Sergeant during his nine years of service. Michael graduated with a degree in Economics from
            Georgia State University in 2013, and after graduation, he started training in taekwondo again with his father in Kennesaw, GA, under Chief Master Arcemont. Michael is now a 3rd-degree
            blackbelt in taekwondo, a Certified Instructor from the American Taekwondo Association, and a black belt in Combat Martial Arts with an instructors tab.</mat-card-content>
    </mat-card>
    <mat-divider></mat-divider>
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="book-image"></div>
            <mat-card-title>Certifications</mat-card-title>
        </mat-card-header>
        <mat-card-content>Michael still trains weekly at Kennesaw and regularly attends certification courses from the American Taekwondo Association. Michael's current certifications include:
            <mat-grid-list cols="2" rowHeight="8em">
                <mat-grid-tile colspan="1" rowspan="1">ATA Legacy Facilitator<br>Teaching ATA Tigers<br>Teaching ATA Karate for Kid<br>Teaching ATA Leadership<br>Teaching ATA Leadership Sparring<br>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="1">ATA Judging Level 1 Corner<br>ATA Judging Level 2 Corner<br> ATA Judging Weapons Level with 1 Star<br>Live Xtreme Level 1<br>Live Xtreme Level 2
                </mat-grid-tile>
            </mat-grid-list>
        </mat-card-content>
    </mat-card>
    <mat-divider></mat-divider>
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="medal-image"></div>
            <mat-card-title>Titles</mat-card-title>
        </mat-card-header>
        <mat-card-content>In addition to his passion for training, Michael is also a staunch competitor. He currently holds the following titles: <mat-grid-list cols="2" rowHeight="4em">
                <mat-grid-tile colspan="1" rowspan="1">Georgia State Champion in Traditional Forms<br>Georgia State Champion in Xtreme Weapons Forms<br>Georgia State Champion in Creative Weapons
                    Forms<br>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="1">Southeast District Champion in Xtreme Weapons Forms<br>Southeast District Champion in Creative Weapons Forms<br>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-card-content>
    </mat-card>
    <mat-divider></mat-divider>
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="news-image"></div>
            <mat-card-title>VoyageATL Magazine</mat-card-title>
            <mat-card-subtitle>November 14, 2018</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>Michael was featured in an article in VoyageATL. In this article Michael shares his thoughts on what it takes to build a great reputation in the a martial arts business.
        </mat-card-content>
        <mat-card-actions style="margin:auto; text-align: center;">
            <button mat-raised-button color="primary" (click)="appService.openUrl('michael')">Read Article</button><br>
        </mat-card-actions>
    </mat-card>
</div>