import { Component } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
	selector: 'app-material',
	templateUrl: './material.component.html',
	styleUrls: ['./material.component.css'],
})
export class MaterialComponent {
	constructor(public appService: AppService) {}
}
