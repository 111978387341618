import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Champions } from '../../../models/app.model';

const champs: Champions[] = [
	{ Name: 'Aarin  Gupta', Year: '2020', Title: 'State Virtual Champion', Event: 'Xtreme Weapons Forms' },
	{ Name: 'Aarin  Gupta', Year: '2020', Title: 'State Virtual Champion', Event: 'Create Weapons Forms' },
	{ Name: 'Aarin  Gupta', Year: '2020', Title: 'State Virtual Champion', Event: 'Create Weapons Forms' },
	{ Name: 'Aarin  Gupta', Year: '2020', Title: 'State Virtual Champion', Event: 'Create Weapons Forms' },
	{ Name: 'Aarin  Gupta', Year: '2020', Title: 'State Virtual Champion', Event: 'Create Weapons Forms' },
	{ Name: 'Aarin  Gupta', Year: '2020', Title: 'State Virtual Champion', Event: 'Traditional Forms' },
	{ Name: 'Aarin  Gupta', Year: '2020', Title: 'State Virtual Champion', Event: 'Xtreme Weapons Forms' },
	{ Name: 'Aminah Mahmood', Year: '2020', Title: 'State Champion', Event: 'Xtreme Forms' },
	{ Name: 'Aminah Mahmood', Year: '2020', Title: 'State Champion', Event: 'Xtreme Weapons Forms' },
	{ Name: 'Aminah Mahmood', Year: '2020', Title: 'District Champion', Event: 'Xtreme Forms' },
	{ Name: 'Aminah Mahmood', Year: '2020', Title: 'District Champion', Event: 'Xtreme Weapons Forms' },
	{ Name: 'Aya Ahmad', Year: '2019', Title: 'State Champion', Event: 'Creative Weapons Forms' },
	{ Name: 'Aya Ahmad', Year: '2019', Title: 'State Champion', Event: 'Xtreme Forms' },
	{ Name: 'Aya Ahmad', Year: '2019', Title: 'State Champion', Event: 'Xtreme Weapons Forms' },
	{ Name: 'Aya Ahmad', Year: '2020', Title: 'State Champion', Event: 'State Weapons Forms' },
	{ Name: 'Chelan  Mclaurin ', Year: '2020', Title: 'State Virtual Champion', Event: 'Create Weapons Forms' },
	{ Name: 'Chelan  Mclaurin ', Year: '2020', Title: 'State Virtual Champion', Event: 'Traditional Weapons Forms' },
	{ Name: 'Chelan  Mclaurin ', Year: '2020', Title: 'State Virtual Champion', Event: 'Xtreme Weapons Forms' },
	{ Name: 'Chelan McLaurin', Year: '2020', Title: 'State Champion', Event: 'Xtreme Weapons Forms' },
	{ Name: 'Greg Harner', Year: '2017', Title: 'State Champion', Event: 'Combat Weapons Sparring' },
	{ Name: 'Greg Harner', Year: '2018', Title: 'District Champion', Event: 'Creative Weapons Forms' },
	{ Name: 'Greg Harner', Year: '2018', Title: 'District Champion', Event: 'Xtreme Weapons Forms' },
	{ Name: 'Greg Harner', Year: '2018', Title: 'State Champion', Event: 'Creative Weapons Forms' },
	{ Name: 'Greg Harner', Year: '2018', Title: 'State Champion', Event: 'Xtreme Weapons Forms' },
	{ Name: 'Greg Harner', Year: '2019', Title: 'District Champion', Event: 'Creative Forms' },
	{ Name: 'Greg Harner', Year: '2019', Title: 'State Champion', Event: 'Creative Forms' },
	{ Name: 'Greg Harner', Year: '2019', Title: 'State Champion', Event: 'Xtreme Forms' },
	{ Name: 'Jason Lee', Year: '2019', Title: 'State Champion', Event: 'Traditional Weapons Forms' },
	{ Name: 'Jason Lee', Year: '2019', Title: 'State Champion', Event: 'Xtreme Weapons Forms' },
	{ Name: 'Jason Lee', Year: '2020', Title: 'State Champion', Event: 'Creative Weapons Forms' },
	{ Name: 'Jason Lee', Year: '2020', Title: 'State Champion', Event: 'Xtreme Weapons Forms' },
	{ Name: 'Jason Lee', Year: '2020', Title: 'District Champion', Event: 'Create Weapons Forms' },
	{ Name: 'Jason Lee', Year: '2020', Title: 'District Champion', Event: 'Xtreme Weapons Forms' },
	{ Name: 'Jaydon Sherman', Year: '2020', Title: 'State Champion', Event: 'Xtreme Weapons Forms' },
	{ Name: 'Meiya Sheren', Year: '2019', Title: 'State Champion', Event: 'Traditional Sparring' },
	{ Name: 'Michael Harner', Year: '2017', Title: 'State Champion', Event: 'Traditional Weapons Forms' },
	{ Name: 'Michael Harner', Year: '2018', Title: 'State Champion', Event: 'Combat Weapons Sparring' },
	{ Name: 'Michael Harner', Year: '2018', Title: 'State Champion', Event: 'Creative Forms' },
	{ Name: 'Michael Harner', Year: '2018', Title: 'State Champion', Event: 'Creative Weapons Forms' },
	{ Name: 'Michael Harner', Year: '2018', Title: 'State Champion', Event: 'Traditional Weapons Forms' },
	{ Name: 'Michael Harner', Year: '2018', Title: 'State Champion', Event: 'Xtreme Forms' },
	{ Name: 'Michael Harner', Year: '2018', Title: 'State Champion', Event: 'Xtreme Weapons Forms' },
	{ Name: 'Michael Harner', Year: '2019', Title: 'District Champion', Event: 'Creative Weapons Forms' },
	{ Name: 'Michael Harner', Year: '2019', Title: 'District Champion', Event: 'Xtreme Weapons Forms' },
	{ Name: 'Michael Harner', Year: '2019', Title: 'State Champion', Event: 'Creative Weapons Forms' },
	{ Name: 'Michael Harner', Year: '2019', Title: 'State Champion', Event: 'Xtreme Weapons Forms' },
	{ Name: 'Molly Drohan', Year: '2020', Title: 'District Champion', Event: 'Create Weapons' },
	{ Name: 'Molly Drohan', Year: '2020', Title: 'District Champion', Event: 'Xtreme Forms' },
	{ Name: 'Molly Drohan', Year: '2020', Title: 'State Champion', Event: 'Create Weapons' },
	{ Name: 'Molly Drohan', Year: '2020', Title: 'State Champion', Event: 'Xtreme Forms' },
	{ Name: 'Nikita Gupta', Year: '2019', Title: 'State Champion', Event: 'Traditional Forms' },
	{ Name: 'Nikita Gupta', Year: '2019', Title: 'State Champion', Event: 'Traditional Weapons Forms' },
	{ Name: 'Nikita Gupta', Year: '2020', Title: 'State Champion', Event: 'Combat Weapons Sparring' },
	{ Name: 'Nikita Gupta', Year: '2020', Title: 'State Champion', Event: 'Traditional Sparring' },
	{ Name: 'Nikita Gupta', Year: '2020', Title: 'State Champion', Event: 'Traditional Weapons Forms' },
	{ Name: 'Novi Jones', Year: '2019', Title: 'State Champion', Event: 'Combat Weapons Sparring' },
	{ Name: 'Sarah Ha', Year: '2017', Title: 'State Champion', Event: 'Xtreme Forms' },
	{ Name: 'Sarah Ha', Year: '2018', Title: 'State Champion', Event: 'Creative Weapons Forms' },
	{ Name: 'Sarah Ha', Year: '2018', Title: 'State Champion', Event: 'Xtreme Forms' },
	{ Name: 'Sarah Ha', Year: '2019', Title: 'District Champion', Event: 'Xtreme Forms' },
	{ Name: 'Sarah Ha', Year: '2019', Title: 'State Champion', Event: 'Traditional Forms' },
	{ Name: 'Sarah Ha', Year: '2019', Title: 'State Champion', Event: 'Xtreme Forms' },
	{ Name: 'Sarah Ha', Year: '2020', Title: 'State Champion', Event: 'Xtreme Forms' },
	{ Name: 'Wiley Barile', Year: '2019', Title: 'District Champion', Event: 'Traditional Sparring' },
];

@Component({
	selector: 'app-champions',
	templateUrl: './champions.component.html',
	styleUrls: ['./champions.component.css'],
})
export class ChampionsComponent implements OnInit {
	// These properties are public because they are used in the view
	public displayedColumns: string[] = ['Name', 'Year', 'Title', 'Event'];
	public dataSource = new MatTableDataSource(champs);
	@ViewChild(MatSort, { static: true }) sort!: MatSort;

	constructor() {}

	ngOnInit() {
		this.dataSource.sort = this.sort;
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
}
