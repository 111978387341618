import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';

@Component({
	selector: 'app-facebook',
	templateUrl: './facebook.component.html',
	styleUrls: ['./facebook.component.css'],
})
export class FacebookComponent implements OnInit {
	// These properties are public because they are used in the view
	public page = this.router.url;

	constructor(private router: Router, public appService: AppService) {}

	ngOnInit() {}
}
