import { Component } from '@angular/core';
import { AppService } from '../../services/app.service';
import { Router } from '@angular/router';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
	selector: 'app-trialoffer',
	templateUrl: './trialoffer.component.html',
	styleUrls: ['./trialoffer.component.css'],
})
export class TrialOfferComponent {
	// These properties are public because they are used in the view
	public tabLabels: string[] = ['Getting Started', 'Billing Account', 'Student Account', 'Purchase', 'Orientation'];

	constructor(public appService: AppService, public router: Router, public firestoreService: FirestoreService) {}
}
