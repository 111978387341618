<section>
  <div>
    <app-cover></app-cover>
    <mat-divider></mat-divider>
    <app-mission></app-mission>
    <mat-divider></mat-divider>
    <app-bestofcobb></app-bestofcobb>
    <mat-divider></mat-divider>
    <app-expertise></app-expertise>
    <mat-divider></mat-divider>
    <app-facebook></app-facebook>
    <mat-divider></mat-divider>
    <app-google></app-google>
    <mat-divider></mat-divider>
    <app-toprated></app-toprated>
  </div>
</section>