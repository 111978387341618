<div class="row">
  <div class="col-md-6 mx-auto">
    <div class="card">
      <div class="card-body">
        <h1 class="text-center pb-4 pt-3">
          <span class="text-primary"><i class="fa fa-lock"></i> Welcome</span>
        </h1>
        <button class="btn btn-light btn-block mb3" (click)="this.onSubmit()">
          Login with Google
        </button>
      </div>
    </div>
  </div>
</div>
