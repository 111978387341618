<mat-card class="material-card mt-1">
    <form #f="ngForm" (ngSubmit)="onAddAccount(f)">
        <mat-vertical-stepper linear>
            <!--
                Account Email
            -->
            <mat-step [editable]="appService.canEditAccount" [stepControl]="f.controls.email">
                <ng-template matStepLabel>Billing Account Email</ng-template>
                <mat-form-field>
                    <input type="text" matInput placeholder="Email" ngModel name="email" required email #email="ngModel" [disabled]="!appService.canEditAccount" />
                </mat-form-field>
                <span class="help-block" *ngIf="!email.valid && email.touched">&nbsp;&nbsp; Please enter a valid email!</span><br>
                <div>
                    <button type="button" mat-button matStepperNext color="primary" [disabled]="!appService.canEditAccount">Next</button>
                </div>
            </mat-step>
            <!--
                Account Name
            -->
            <mat-step [editable]="appService.canEditAccount" ngModelGroup="names" [stepControl]="f.controls.names">
                <ng-template matStepLabel>Billing Account Name (as it will appear on payment)</ng-template>
                <mat-form-field>
                    <input type="text" matTooltip="This is the person that will be paying for the classes" matInput placeholder="First Name" [ngModel]="givenContact!.names!.givenName" name="givenName"
                        required [disabled]="!appService.canEditAccount">
                </mat-form-field><br>
                <mat-form-field>
                    <input type="text" matTooltip="This is the person that will be paying for the classes" matInput placeholder="Last Name" [ngModel]="givenContact!.names!.familyName"
                        name="familyName" required [disabled]="!appService.canEditAccount">
                </mat-form-field>
                <div>
                    <button type="button" mat-button matStepperNext color="primary" [disabled]="!appService.canEditAccount">Next</button>
                </div>
            </mat-step>
            <!--
                Account Gender
            -->
            <mat-step [editable]="appService.canEditAccount" [stepControl]="f.controls.gender">
                <ng-template matStepLabel>Billing Account Gender</ng-template>
                <mat-form-field>
                    <mat-label>Gender</mat-label>
                    <select matNativeControl [ngModel]="givenContact.mas!.accountSettings!.gender" name="gender" required [disabled]="!appService.canEditAccount">
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                    </select>
                </mat-form-field>
                <div>
                    <button type="button" mat-button matStepperNext color="primary" [disabled]="!appService.canEditAccount">Next</button>
                </div>
            </mat-step>
            <!--
                Account Address
             -->
            <mat-step [editable]="appService.canEditAccount" ngModelGroup="addresses" [stepControl]="f.controls.addresses">
                <ng-template matStepLabel>Billing Account Address</ng-template>
                <mat-form-field>
                    <input type="text" matTooltip="example: 3246 Atlanta Road SE" type="text" matInput placeholder="Street" [ngModel]="givenContact!.addresses!.streetAddress" name="streetAddress"
                        required [disabled]="!appService.canEditAccount" />
                </mat-form-field><br>
                <mat-form-field>
                    <input type="text" matTooltip="example: Smyrna" type="text" matInput placeholder="City" [ngModel]="givenContact!.addresses!.city" name="city" required
                        [disabled]="!appService.canEditAccount" />
                </mat-form-field><br>
                <mat-form-field>
                    <input type="text" matTooltip="example: GA" type="text" matInput placeholder="State" [ngModel]="givenContact!.addresses!.region" name="region" required
                        [disabled]="!appService.canEditAccount" />
                </mat-form-field><br>
                <mat-form-field>
                    <input type="text" matTooltip="example: 30080" type="text" matInput placeholder="Postal" [ngModel]="givenContact!.addresses!.postalCode" name="postalCode" required
                        [disabled]="!appService.canEditAccount" />
                </mat-form-field>
                <div>
                    <button type="button" mat-button matStepperNext color="primary" [disabled]="!appService.canEditAccount">Next</button>
                </div>
            </mat-step>
            <!--
                Account Phone
            -->
            <mat-step [editable]="appService.canEditAccount" [stepControl]="f.controls.phoneNumbers">
                <ng-template matStepLabel>Billing Account Phone Number</ng-template>
                <mat-form-field>
                    <input type="text" matTooltip="example: 678-381-8305" matInput placeholder="Phone" [ngModel]="givenContact!.phoneNumbers!.value" name="phone" required
                        [disabled]="!appService.canEditAccount" />
                </mat-form-field>
                <div>
                    <button type="submit" mat-raised-button matStepperNext color="primary" [disabled]="!f.valid">Submit</button>
                </div>
            </mat-step>
        </mat-vertical-stepper>
    </form>
</mat-card>