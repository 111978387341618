import { Component, OnDestroy } from '@angular/core';
import { DialogComponent } from '../../layout/badges/dialog/dialog.component';
import { AppService } from 'src/app/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-tigers',
	templateUrl: './tigers.component.html',
	styleUrls: ['./tigers.component.css'],
})
export class TigersComponent implements OnDestroy {
	private contractMessageSubscription!: Subscription;
	private testingMessageSubscription!: Subscription;

	constructor(public appService: AppService, public dialog: MatDialog) {}

	/***********************************************************************************************************************
	 * @name openContractMessage
	 * @memberof MissionComponent
	 * @author Greg Harner
	 * @description opens a dialog page this share mission critical information with customers
	 * @return void
	 * @link
	 ***********************************************************************************************************************/
	public openContractMessage(): void {
		this.appService.dialogMessage = `Yongsa believes requiring our customers to sign a contract is contrary to our 
		mission of "making champions." We want our students to train with us because they have an extraordinary championship
		attitude and not because they are stuck in a contract.`;
		const dialogRef = this.dialog.open(DialogComponent, {
			height: '400px',
			width: '600px',
		});
		this.contractMessageSubscription = dialogRef.afterClosed().subscribe();
	}

	/***********************************************************************************************************************
	 * @name openTestingMessage
	 * @memberof MissionComponent
	 * @author Greg Harner
	 * @description opens a dialog page this share mission critical information with customers
	 * @return void
	 * @link
	 ***********************************************************************************************************************/
	public openTestingMessage(): void {
		this.appService.dialogMessage = `When a martial art school ties revenue to testing eligibility, it creates a 
		financial incentive to test students insufficiently prepared for the next rank.`;
		const dialogRef = this.dialog.open(DialogComponent, {
			height: '400px',
			width: '600px',
		});
		this.testingMessageSubscription = dialogRef.afterClosed().subscribe();
	}

	ngOnDestroy(): void {
		if (this.contractMessageSubscription) this.contractMessageSubscription.unsubscribe();
		if (this.testingMessageSubscription) this.testingMessageSubscription.unsubscribe();
	}
}
