<div fxLayout="row" fxLayoutAlign="center top" class="container">
    <div style="width: 30%;">
        <mat-card style="width: 100%;">
            <mat-card-header>
                <mat-card-title>Hours of Operation</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <table style="width: 100%;">
                    <tr>
                        <td>Monday</td>
                        <td>4pm - 7pm</td>
                    </tr>
                    <tr>
                        <td>Tuesday</td>
                        <td>4pm - 7pm</td>
                    </tr>
                    <tr>
                        <td>Wednesday</td>
                        <td>4pm - 7pm</td>
                    </tr>
                    <tr>
                        <td>Thursday</td>
                        <td>4pm - 7pm</td>
                    </tr>
                    <tr>
                        <td>Friday</td>
                        <td>4pm - 7pm</td>
                    </tr>
                    <tr>
                        <td>Saturday</td>
                        <td>11am - 2pm</td>
                    </tr>
                    <tr>
                        <td>Sunday</td>
                        <td>Closed</td>
                    </tr>
                </table>
            </mat-card-content>
        </mat-card>
    </div>
    <div style="width: 70%; margin-left: 1vw;">
        <h1>Yongsa Martial Arts</h1>
        <p>3246 Atlanta Road SE<br />Smyrna, GA 30080</p>We are always available to answer any questions you may have via phone, or you may email at info@yongsa.net, and a representative will contact
        you within 24 hours. Thank you!