import { Component } from '@angular/core';

@Component({
	selector: 'app-adult',
	templateUrl: './adult.component.html',
	styleUrls: ['./adult.component.css'],
})
export class AdultComponent {
	constructor() {}
}
