import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-tigerstories',
	templateUrl: './tigerstories.component.html',
	styleUrls: ['./tigerstories.component.css'],
})
export class TigerStoriesComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
