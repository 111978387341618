<app-cover *ngIf="page==='/toprated'"></app-cover>
<div>
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar class="header-image"></div>
      <mat-card-title>Top Rated Local</mat-card-title>
      <mat-card-subtitle>2019 Winner</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>Top Rated Local® is pleased to announce that Yongsa Martial Arts is the 2019 award winner for <i>The Best Martial Arts Centers in Smyrna</i>! <a href=""
        routerLink="/alice"><b>Alice</b></a> is looking forward to making the list again in 2020. If you are a member of the Yongsa Family, you can help us make the list by leaving us both a Google
      and a Facebook review. We don't want to let Alice down; do we? </mat-card-content>
    <mat-card-actions style="margin:auto; text-align: center;">
      <button mat-raised-button color="primary" (click)="appService.openUrl('topRated')">Learn More</button><br>
    </mat-card-actions>
  </mat-card>
</div>