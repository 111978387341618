import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';

@Component({
	selector: 'app-google',
	templateUrl: './google.component.html',
	styleUrls: ['./google.component.css'],
})
export class GoogleComponent {
	// These properties are public because they are used in the view
	public page = this.router.url;

	constructor(private router: Router, public appService: AppService) {}
}
