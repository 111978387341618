import { Injectable } from '@angular/core';

import {
	collection,
	CollectionReference,
	collectionSnapshots,
	deleteDoc,
	doc,
	Firestore,
	getDoc,
	getDocs,
	orderBy,
	query,
	setDoc,
	where,
	addDoc,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class FirestoreService {
	constructor(private afs: Firestore, private pie: Firestore) {}

	public async mergeDocument(col: string, id: string, payload: any): Promise<any> {
		const schedule = doc(this.afs, col, id);
		return await setDoc(schedule, payload, { merge: true });
	}

	public async addDocument(col: string, payload: any): Promise<any> {
		return await setDoc(doc(this.afs, col), payload);
	}

	public onCheckEmail() {
		const q = query<any>(collection(this.afs, 'mas-programs') as CollectionReference<any>, where('mas.accountSettings.billing', '==', 'true'));
		return getDocs(q);
	}
}
