<div id="cover">
	<mat-card>
		<img
			mat-card-image
			src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2Fhero.png?alt=media&token=944989b1-7bee-439b-aeb4-b56af4f163c4"
			alt="hero"
		/>
	</mat-card>
</div>
<div id="hero">
	<mat-card>
		<mat-card-header>
			<div mat-card-avatar class="star-image"></div>
			<mat-card-title>Honoring our Heroes</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			At Yongsa, we believe our services are very reasonably priced. Our no-haggle prices are clearly published and, for
			the most part, we do not offer discounts. However, there is a segment of our population that we believe have
			earned some extra special pricing. These are the men and women who help keep us safe, defend our freedom, and
			educate our children. To our community heroes, we are happy to offer a 25% discount off our regular price.
		</mat-card-content>
	</mat-card>
</div>
