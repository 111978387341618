import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
	constructor(private authService: AuthService, private router: Router) {}
	ngOnInit() {
		this.authService.getAuth().subscribe(auth => {
			if (auth) {
				this.router.navigate(['/']);
				return;
			}
		});
	}

	onSubmit() {
		this.authService
			.login()
			.then(() => {
				this.router.navigate(['/']);
			})
			.catch(error => {
				console.log(error.message);
			});
	}
}
