import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from '../components/about/about.component';
import { BestOfCobbComponent } from '../components/about/bestofcobb/bestofcobb.component';
import { CoverComponent } from '../components/about/cover/cover.component';
import { FacebookComponent } from '../components/about/facebook/facebook.component';
import { GoogleComponent } from '../components/about/google/google.component';
import { ExpertiseComponent } from '../components/about/expertise/expertise.component';
import { MissionComponent } from '../components/about/mission/mission.component';
import { TopratedComponent } from '../components/about/toprated/toprated.component';
import { AliceComponent } from '../components/crew/alice/alice.component';
import { GregComponent } from '../components/crew/greg/greg.component';
import { MichaelComponent } from '../components/crew/michael/michael.component';
import { TaraComponent } from '../components/crew/tara/tara.component';
import { LoginComponent } from '../components/login/login.component';
import { ChampionsComponent } from '../components/more/champions/champions.component';
import { ContactComponent } from '../components/more/contact/contact.component';
import { HeroComponent } from '../components/more/hero/hero.component';
import { MaterialComponent } from '../components/more/material/material.component';
import { PieComponent } from '../components/more/pie/pie.component';
import { PrivacyComponent } from '../components/more/privacy/privacy.component';
import { SubscriptionComponent } from '../components/more/subscription/subscription.component';
import { TermsComponent } from '../components/more/terms/terms.component';
import { AdultComponent } from '../components/programs/adult/adult.component';
import { CmaComponent } from '../components/programs/cma/cma.component';
import { KarateForKidsComponent } from '../components/programs/karateforkids/karateforkids.component';
import { TigersComponent } from '../components/programs/tigers/tigers.component';
import { ClassesComponent } from '../components/schedules/classes/classes.component';
import { FightDirtyScheduleComponent } from '../components/schedules/fightdirtyschedule/fightdirtyschedule.component';
import { NinjaNightScheduleComponent } from '../components/schedules/ninjanightschedule/ninjanightschedule.component';
import { TournamentsComponent } from '../components/schedules/tournaments/tournaments.component';
import { FailureComponent } from '../components/trial/failure/failure.component';
import { RejectComponent } from '../components/trial/reject/reject.component';
import { SuccessComponent } from '../components/trial/success/success.component';
import { TrialOfferComponent } from '../components/trial/trialoffer.component';
import { TigerStoriesComponent } from '../components/more/tigerstories/tigerstories.component';
import { YasComponent } from '../components/programs/yas/yas.component';

const routes: Routes = [
	{ path: '', component: AboutComponent },

	{ path: 'bestofcobb', component: BestOfCobbComponent },
	{ path: 'expertise', component: ExpertiseComponent },
	{ path: 'mission', component: MissionComponent },
	{ path: 'cover', component: CoverComponent },
	{ path: 'facebook', component: FacebookComponent },
	{ path: 'google', component: GoogleComponent },
	{ path: 'toprated', component: TopratedComponent },

	{ path: 'tigers', component: TigersComponent },
	{ path: 'cma', component: CmaComponent },
	{ path: 'karateforkids', component: KarateForKidsComponent },
	{ path: 'adult', component: AdultComponent },
	{ path: 'yas', component: YasComponent },

	{ path: 'tournaments', component: TournamentsComponent },
	{ path: 'classes', component: ClassesComponent },
	{ path: 'fightdirtyschedule', component: FightDirtyScheduleComponent },
	{ path: 'ninjanightschedule', component: NinjaNightScheduleComponent },

	{ path: 'alice', component: AliceComponent },
	{ path: 'michael', component: MichaelComponent },
	{ path: 'tara', component: TaraComponent },
	{ path: 'greg', component: GregComponent },

	{ path: 'material', component: MaterialComponent },
	{ path: 'champs', component: ChampionsComponent },
	{ path: 'hero', component: HeroComponent },
	{ path: 'pie', component: PieComponent },
	{ path: 'terms', component: TermsComponent },
	{ path: 'privacy', component: PrivacyComponent },
	{ path: 'contact', component: ContactComponent },
	{ path: 'subscription', component: SubscriptionComponent },
	{ path: 'stories', component: TigerStoriesComponent },

	{ path: 'trial', component: TrialOfferComponent },
	{ path: 'reject', component: RejectComponent },
	{ path: 'failure', component: FailureComponent },
	{ path: 'success', component: SuccessComponent },

	{ path: 'login', component: LoginComponent },
	{ path: '**', redirectTo: '/' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
