<div id="cover">
    <mat-card>
        <img mat-card-image src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/lifeskills%2Frespect.png?alt=media&token=0a5fd0ff-3497-4028-9faf-42a8f6aad175" alt="Fight Dirty">
    </mat-card>
</div>
<div id="fightdirty">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="ksb-image"></div>
            <mat-card-title>Yongsa Students are Champions for the Environment!</mat-card-title>
        </mat-card-header>
        <mat-card-content>Yongsa has <a href="https://keepsmyrnabeautiful.com/adopt-a-mile/" target="_blank">
                <span style="font-weight: bolder; font-size: 1.1em">adopted</span></a> a 1-mile section of the <a href="https://www.silvercometga.com/" target="_blank"><span
                    style="font-weight: bolder; font-size: 1.1em">Silver Comet Trail</span></a>, and we are committed to keeping our community clean. Six times per year, we meet to take a group walk
            and have fun while picking up trash along the way. Food and refreshments are served to the volunteers before we head out. Not only does community service feel good, but it can also earn
            you a Citizenship medal, patch, or prize!</mat-card-content>
        <mat-card-content>
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="Year">
                    <th mat-header-cell *matHeaderCellDef>Year</th>
                    <td mat-cell *matCellDef="let fights"> {{fights.Year}} </td>
                </ng-container>
                <ng-container matColumnDef="Cleanups">
                    <th mat-header-cell *matHeaderCellDef>Fights</th>
                    <td mat-cell *matCellDef="let fights"> {{fights.Cleanups}} </td>
                </ng-container>
                <ng-container matColumnDef="Volunteers">
                    <th mat-header-cell *matHeaderCellDef>Volunteers</th>
                    <td mat-cell *matCellDef="let fights"> {{fights.Volunteers}} </td>
                </ng-container>
                <ng-container matColumnDef="Hours">
                    <th mat-header-cell *matHeaderCellDef>Hours</th>
                    <td mat-cell *matCellDef="let fights"> {{fights.Hours}} </td>
                </ng-container>
                <ng-container matColumnDef="Bags">
                    <th mat-header-cell *matHeaderCellDef>Bags</th>
                    <td mat-cell *matCellDef="let fights"> {{fights.Bags}} </td>
                </ng-container>
                <ng-container matColumnDef="Pounds">
                    <th mat-header-cell *matHeaderCellDef>Pounds</th>
                    <td mat-cell *matCellDef="let fights"> {{fights.Pounds}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card-content>
        <mat-card-content>With the losing of Covid-19 restrictions, we have resumed our Fight Dirty program. However, at this time, students can only sign up for the event in the studio. In the coming
            weeks, we will resume our online signup.</mat-card-content>
        <mat-card-actions style="margin:auto; text-align: center;">
            <button mat-raised-button color="primary" (click)="appService.openUrl('fightdirty')" [disabled]="true">Volunteer</button><br>
        </mat-card-actions>
    </mat-card>
</div>