<div id="cover">
    <mat-card>
        <img mat-card-image src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2Faharner.jpg?alt=media&token=4f1c2d1c-47c0-4da0-9777-f817ef606f6c" alt="Alice Harner">
    </mat-card>
</div>
<mat-divider></mat-divider>
<div id="aharner">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="paw-image"></div>
            <mat-card-title>Best Friends</mat-card-title>
        </mat-card-header>
        <mat-card-content>If you want to know more about Alice, her story was featured on the "Best Friends" website. </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="appService.openUrl('bestFriends')">Read Story</button><br>
        </mat-card-actions>
    </mat-card>
    <mat-divider></mat-divider>
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="safety-image"></div>
            <mat-card-title>Dog Safety</mat-card-title>
        </mat-card-header>
        <mat-card-content>There are a lot of health benefits to owning a dog. According to an article by the Center for Disease Control, dogs have been proven to reduce stress. Maybe that is why 36%
            of American households own a dog. </mat-card-content>
        <mat-card-content>Alice is an amazingly gentle dog, and she plays a role in our "Dog Safety" training for Tiger students. She is generally allowed to walk around the school. For your child's
            safety and the safety of Alice, we ask that you carefully supervise your child when they are playing with her. If you, or your child, have a fear of dogs, please let us know, and we will
            put her in her enclosure when you are visiting. </mat-card-content>
        <mat-card-actions style="margin:auto; text-align: center;">
            <button mat-raised-button color="primary" (click)="appService.openUrl('dog')">Activity Book</button><br>
        </mat-card-actions>
    </mat-card>
</div>