<!--
    This component displays pop up messages related to strategic topics concerning our mission, "Making Champions." It is
    meant to give customers and potential customers a deeper understanding of our business principle, but in a way that
    does not distract from the central website content.
-->
<mat-card>
    <mat-card-header>
        <div mat-card-avatar class="info-image"></div>
        <mat-card-title>{{appService.dialogTitle}}</mat-card-title>
        <mat-card-subtitle>{{appService.dialogSubtitle}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>{{appService.dialogMessage}}</mat-card-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button color="primary" mat-dialog-close>Close</button>
    </mat-dialog-actions>
</mat-card>