<app-cover *ngIf="page==='/expertise'"></app-cover>
<div>
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="expertise-image"></div>
            <mat-card-title>Expertise.com</mat-card-title>
            <mat-card-subtitle>Best Martial Arts Schools in Atlanta</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>Expertise.com has the goal of connecting people with the best local experts. They scored Atlanta Martial Arts Schools on more than 25 variables across five categories, and
            analyzed the results to create a hand-picked list of the best. Yongsa is very proud to be on their list. </mat-card-content>
        <mat-card-actions style="margin:auto; text-align: center;">
            <a href="https://www.expertise.com/ga/atlanta/karate-taekwondo-martial-arts" target="_blank" style="display:inline-block; border:0;"><img style="width:200px; display:block;" width="200"
                    height="160" src="https://res.cloudinary.com/expertise-com/image/upload/f_auto,fl_lossy,q_auto/w_auto/remote_media/awards/ga_atlanta_karate-taekwondo-martial-arts_2021.svg"
                    alt="Top Martial Arts Schools in Atlanta" /></a>
        </mat-card-actions>
    </mat-card>
</div>