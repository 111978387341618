<div id="animation">
    <mat-card style="margin: auto; text-align: center;">
        <img mat-card-image src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2FCompetition.jpg?alt=media&token=3c4339f6-bce5-483f-8ae5-c58e012216ee" [@didyouknow]="animationState"
            (click)="onAnimate()">
        <img mat-card-image src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2Fsharptournaments.jpg?alt=media&token=ffc99fed-a525-4e77-95bc-6271203f08ea"
            [@benefits]="animationState" (click)="onAnimate()">
        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="onAnimate()">{{animationState }}</button><br>
        </mat-card-actions>
    </mat-card>
</div>
<mat-divider></mat-divider>
<div id="ataonline">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="ataonline-image"></div>
            <mat-card-title>ATA Tournaments</mat-card-title>
            <mat-card-subtitle>Worldwide</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>Yongsa Students are allowed to participate in any of the hundreds of tournaments around the world. Visit ATA Online for a complete list of tournaments.</mat-card-content>
        <mat-card-actions style="margin:auto; text-align: center;">
            <button mat-raised-button color="primary" (click)="appService.openUrl('ataonline')">ATA Online</button><br>
        </mat-card-actions>
    </mat-card>
</div>
<div id="region">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="info-image"></div>
            <mat-card-title>Region 108</mat-card-title>
            <mat-card-subtitle>The Great 108</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="flex">
                <div style=" margin-right: 20px">
                    <img style="width: 100%; height: 100%;;"
                        src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2FRegion%20108.svg?alt=media&token=416f8f4b-ebad-46e9-b6a0-6ac3f2b28f14" alt="Region 108">
                </div>
                <div>The American Taekwondo Association is divided into Regions. Each Region host five tournaments per year. Yongsa is located in Region 108, which includes Georgia, Alabama, and the
                    Florida panhandle. While our members are welcome to participate in any tournament worldwide, Yongsa features all Region 108 tournaments. We are very fortunate because the metro
                    Atlanta area generally hosts two to three tournaments each year.</div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<div id="valdosta">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="valdosta-image"></div>
            <mat-card-title>Valdosta</mat-card-title>
            <mat-card-subtitle>Super Regional Championship</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>Master Mitchell Church hosts the Valdosta Regional Championship. The Guest of Honor is our own Region 108 Grand Master Emeritus Soon Ho Lee.</mat-card-content>
        <mat-card-actions style="margin:auto; text-align: center;">
            <button mat-raised-button color="primary" (click)="appService.openUrl('valdosta')">Learn More</button><br>
        </mat-card-actions>
    </mat-card>
</div>