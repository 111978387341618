<app-cover *ngIf="page==='/facebook'"></app-cover>
<div>
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar class="facebook-image"></div>
      <mat-card-title>Facebook Profile</mat-card-title>
      <mat-card-subtitle>Our Family Plays Here</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>Yongsa Martial Arts goes by another name to those that know us. We are the "Yongsa Family"! When you spend two or more hours each week with the same people, you have time to
      build bonds with one another. At Yongsa, we build strong relationships! Perhaps it goes back to the <i>esprit de corps</i> fostered in us while serving in the Marines. Whatever the source, it
      has rubbed off on our students and their families. Please don't take our word for it. Visit our Facebook page and see the dynamics of our family for yourself!</mat-card-content>
    <mat-card-actions style="margin:auto; text-align: center;">
      <button mat-raised-button color="primary" (click)="appService.openUrl('facebook')">Facebook Page</button>
      <button mat-raised-button color="primary" (click)="appService.openUrl('facebookReviews')">Facebook Reviews</button><br>
    </mat-card-actions>
  </mat-card>
</div>