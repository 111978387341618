export const firebase = {
	production: false,
	Cfg: {
		apiKey: 'AIzaSyCnnKIycurp2XrofiKuOOhx17g0U2vAOSk',
		appId: '1:472191207902:web:fa19a266bfccba0baa354f',
		authDomain: 'mas-development-53ac7.firebaseapp.com',
		databaseURL: 'https://mas-development-53ac7.firebaseio.com',
		locationId: 'us-central',
		measurementId: 'G-99NDJ28KET',
		messagingSenderId: '472191207902',
		projectId: 'mas-development-53ac7',
		storageBucket: 'mas-development-53ac7.appspot.com',
	},
	apiEndpoint: 'https://us-central1-mas-development-53ac7.cloudfunctions.net/',
	googleDomain: 'yongsa.net',
	offSetDays: 10,
	PayPal_Client_Id: 'AT7N55QgBstZFh1VPSXCsGLt2NBi94EOEpIthf3eVhInpePmMw2o7-08iZv_yOeUzpS5jjcmtPFvWAGY',
};
