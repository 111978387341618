<app-cover *ngIf="page === '/mission'"></app-cover>
<div>
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="medal-image"></div>
            <mat-card-title>Making Champions!</mat-card-title>
            <mat-card-subtitle>a mission that makes sense</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>At Yongsa, we make champions! which includes but is not limited to <a href="" routerLink="/champs">tournament champions</a>. We also believe in being champions for the
            community, <a href="" routerLink="/fightdirtyschedule">environment</a>, <a href="" routerLink="/pie">education</a>, and much more. We try to focus our policies and activities on practices
            that will help us achieve our mission. To help our customers and potential customers understand more about our mission, we have added badges to our website that look like this.<a
                (click)="openMissionMessage()">
                <mat-icon>info</mat-icon>
            </a>
            <p> Understanding our mission will also help you understand our approach to instruction. First, we embrace martial arts as a sport. We require our students to participate in tournaments to
                advance in rank. No matter what benefits you expect from enrolling in martial arts, we find that tournament participation will only expand those benefits. Additionally, our instructors
                are not concerned about the difference between good and bad. We focus on the difference between good and great! Excellence is in the attention to detail that we demand from our
                students. If high expectations, rigorous training, and attention to detail are what you are looking for in a martial arts program. Yongsa is the school for you. </p>
        </mat-card-content>
        <!-- mat-card-content>If you or your child is ready to take the path of a Champion you can get started today our $55 trial offer.</mat-card-content>
        <mat-card-actions >
            <button mat-raised-button color="primary" routerLink="/trial">Trial Offer</button><br>
        </mat-card-actions -->
    </mat-card>
</div>