<app-cover *ngIf="page==='/bestofcobb'"></app-cover>
<div>
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="bestofcobb-image"></div>
            <mat-card-title>Best of Cobb</mat-card-title>
            <mat-card-subtitle>An Award from Cobb Life Magazine</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>Cobb Life Magazine, a publication of the Marietta Daily Journal, declared Yongsa Martial Arts the Winner in the 2021 annual contest for the best martial arts school in Cobb
            County. Yongsa has the distinction of winning this award for the second consecutive year. To learn more, listen to our Cheif Instructor, Michael Harner, in his podcast interview with the
            Marietta Daily Journal.</mat-card-content>
        <mat-card-content>We are honored to have been nominated by the Yongsa Family and even more honored to be selected. </mat-card-content>
        <mat-card-actions style="margin:auto; text-align: center;">
            <button mat-raised-button color="primary" (click)="appService.openUrl('bestofcobb')">MJD podcast</button><br>
        </mat-card-actions>
    </mat-card>
</div>