import { Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'app-getstarted',
	templateUrl: './getstarted.component.html',
	styleUrls: ['./getstarted.component.css'],
})
export class GetstartedComponent {
	@Output() start = new EventEmitter<number>();
	constructor() {}

	gettingStarted() {
		/* 		const head = document.getElementsByTagName('head')[0];
		const _js = document.createElement('script');
		_js.type = 'text/javascript';
		_js.appendChild(document.createTextNode('{your js code here}'));
		head.appendChild(_js); */
		this.start.emit(1);
	}
}
