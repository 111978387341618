import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppService } from '../../../../services/app.service';
import { FirestoreService } from '../../../../services/firestore.service';
import { Router } from '@angular/router';
import { CustomFunctions } from 'src/app/models/global.model';

@Component({
	selector: 'app-student',
	templateUrl: './student.component.html',
	styleUrls: ['./student.component.css'],
})
export class StudentComponent {
	// These properties are public because they are used in the view
	@Output() student = new EventEmitter<number>();
	public FormGroup!: FormGroup;
	public age = 0;
	public singleAccount = true;

	constructor(private firestoreService: FirestoreService, public appService: AppService, private router: Router) {}

	/***********************************************************************************************************************
	 * @name getAge
	 * @memberof StudentComponent
	 * @author Greg Harner
	 * @description gets the age of student. Redirects to reject page of student is under four
	 * @argument {birthdate: string}
	 * @method appService.getAge(dateOfBirth)
	 * @return void
	 * @link
	 ***********************************************************************************************************************/
	public getAge(birthday: string): void {
		this.age = CustomFunctions.calculateAge(birthday);
		if (this.age < 4) this.router.navigateByUrl('/reject', { state: { reason: 'isUnderFour' } });
	}

	/***********************************************************************************************************************
	 * @name onSingleAccountCheck
	 * @memberof StudentComponent
	 * @author Greg Harner
	 * @description if the student account will also be the billing account this function updates the billing account with
	 * student data. When complete it emits a value that will take the user to the payment step.
	 * @method firestoreService.mergeDocument()
	 * @return void
	 * @link
	 ***********************************************************************************************************************/
	public onSingleAccountCheck(): void {
		this.appService.billingAccount.mas!.accountSettings!.birthday = this.appService.studentBirthday;
		this.appService.billingAccount.mas!.accountSettings!.member = 'true';
		this.firestoreService
			.mergeDocument('mas-accounts', this.appService.billingAccountId, this.appService.billingAccount)
			.then(() => {
				this.appService.canEditStudent = false;
				this.appService.canMakePayment = true;
				this.student.emit(3);
			})
			.catch(error => console.log(error.message));
	}

	/***********************************************************************************************************************
	 * @name onSingleAccountCheck
	 * @memberof StudentComponent
	 * @author Greg Harner
	 * @description if the student account is different from the billing account this function adds an account for the
	 * student. When complete it emits a value that will take the user to the payment step.
	 * @argument {f: an}
	 * @method firestoreService.addDocument()
	 * @return void
	 * @link
	 ***********************************************************************************************************************/
	public onAddStudent(f: any): void {
		//create a deep copy of the billing account to use as the student account
		this.appService.studentAccount = JSON.parse(JSON.stringify(this.appService.billingAccount));

		//over write billing account values with student account values
		this.appService.studentAccount.names = f.value.names;
		this.appService.studentAccount.mas!.accountSettings!.birthday = this.appService.studentBirthday;
		this.appService.studentAccount.mas!.accountSettings!.billing = 'false';
		this.appService.studentAccount.mas!.accountSettings!.member = 'true';
		this.appService.studentAccount.mas!.accountSettings!.gender = f.value.gender;
		if (typeof f.value.school !== 'undefined')
			this.appService.studentAccount.mas!.accountSettings!.school = f.value.school;
		this.appService.studentAccount.mas!.accountSettings!.status = 'Trial';
		this.appService.studentAccount.mas!.linkedAccounts = [];
		this.appService.studentAccount.mas!.linkedAccounts[0] = { accountId: this.appService.billingAccountId };

		let success = true;
		this.firestoreService
			.addDocument('mas-accounts', this.appService.studentAccount)
			.then(result => {
				this.appService.studentAccountId = result;
			})
			.catch(error => {
				success = false;
				console.log(error.message);
			})
			.finally(() => {
				if (success) {
					if (typeof this.appService.billingAccount.mas!.linkedAccounts !== 'undefined') {
						this.appService.billingAccount.mas!.linkedAccounts.push({ accountId: this.appService.studentAccountId });
					} else {
						this.appService.billingAccount.mas!.linkedAccounts = [];

						this.appService.billingAccount.mas!.linkedAccounts[0] = { accountId: this.appService.studentAccountId };
					}
					this.firestoreService
						.mergeDocument('mas-accounts', this.appService.billingAccountId, this.appService.billingAccount)
						.catch(error => console.log(error.message))
						.then(() => {
							this.appService.canEditStudent = false;
							this.appService.canMakePayment = true;
							this.student.emit(3);
						});
				}
			});
	}
}
