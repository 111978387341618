<div id="material">
    <mat-card>
        <img mat-card-image src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/lifeskills%2Fdiscipline.png?alt=media&token=6103f58f-b365-4f13-9bd0-eb089e745297" alt="Student Material">
    </mat-card>
</div>
<div id="material" class="mat-card-cvr">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="video-library-image"></div>
            <mat-card-title>Student Material</mat-card-title>
            <mat-card-subtitle>Videos and Zoom Classes</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>We are providing a collection of online resources to help our students continue their martial arts journey while following the guidelines for social distancing. These
            resources are intended for current and active students and include zoom classes and video-based training.</mat-card-content>
        <mat-card-content>Stay safe, stay strong, and train hard! </mat-card-content>
        <mat-card-actions style="margin:auto; text-align: center;">
            <button mat-raised-button color="primary" (click)="appService.openUrl('material')">Online Material</button><br>
        </mat-card-actions>
    </mat-card>
</div>