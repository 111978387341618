<footer fxLayout="row" fxLayoutAlign="center top" class="container">
	<div fxLayout="row" fxLayoutAlign="center top" class="container">
		<div style="padding-right: 3vh;"><a href=""
				routerLink="">About</a><br /><a href="" routerLink="/contact">Contact</a><br /><a
				href="https://www.facebook.com/YongsaMartialArts/">Facebook</a><br /><a
				href="https://www.instagram.com/yongsa.ma/">Instagram</a></div>
		<div style="padding-left: 3vh; padding-right: 3vh;"><a href="" routerLink="/pie">Partners in
				Education</a><br /><a
				href="https://campbellmiddleschool.rocks" target="_blank">Campbell Middle School Rocks</a><br /><a
				href="https://nickajack.rocks" target="_blank">Nickajack Rocks</a><br /><a href="https://teasley.rocks"
				target="_blank">Teasley Rocks</a>
		</div>
		<div style="padding-left: 3vh; padding-right: 3vh;">
			<a href="" routerLink="/hero">Hero Discount</a><br /><a href="" routerLink="/fightdirtyschedule">Fight
				Dirty</a><br /><a href="" routerLink="/tournaments">Tournaments</a><br /><a
				href="https://www.atamartialarts.com/" target="_blank">ATA International</a>
		</div>
		<div style="padding-left: 3vh;">
			<a href="" routerLink="/privacy">Privacy</a><br /><a href="" routerLink="/terms">Terms of Use</a>
		</div>
	</div>
</footer>
<div id="footerbottom">Yongsa Martial Arts - Making Champions!</div>