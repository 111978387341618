import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';

@Component({
	selector: 'app-bestofcobb',
	templateUrl: './bestofcobb.component.html',
	styleUrls: ['./bestofcobb.component.css'],
})
export class BestOfCobbComponent {
	// These properties are public because they are used in the view
	public page = this.router.url;

	constructor(private router: Router, public appService: AppService) {}
}
