import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from 'src/app/services/app.service';
import { Fights } from '../../../models/app.model';

const fights: Fights[] = [
	{ Year: '2021', Cleanups: 2, Volunteers: 32, Hours: 46.95, Bags: 17, Pounds: 255 },
	{ Year: '2020', Cleanups: 2, Volunteers: 8, Hours: 16, Bags: 4, Pounds: 60 },
	{ Year: '2019', Cleanups: 6, Volunteers: 47, Hours: 79.5, Bags: 11.5, Pounds: 172.5 },
	{ Year: '2018', Cleanups: 5, Volunteers: 59, Hours: 96, Bags: 12, Pounds: 180 },
];

@Component({
	selector: 'app-fightdirtyschedule',
	templateUrl: './fightdirtyschedule.component.html',
	styleUrls: ['./fightdirtyschedule.component.css'],
})
export class FightDirtyScheduleComponent {
	public displayedColumns: string[] = ['Year', 'Cleanups', 'Volunteers', 'Hours', 'Bags', 'Pounds'];
	public dataSource = new MatTableDataSource(fights);

	constructor(public appService: AppService) {}
}
