<mat-card class="material-card mt-1">
    <form #f="ngForm" (ngSubmit)="onAddStudent(f)">
        <mat-vertical-stepper linear>
            <!--
                Student's Date of Birth
            -->
            <mat-step [editable]="appService.canEditStudent" [stepControl]="f.controls.birthday">
                <ng-template matStepLabel>Student's Birthday</ng-template>
                <mat-form-field>
                    <input type="date" matInput ngModel #birthday="ngModel"
                        name="birthday"
                        required
                        [disabled]="!appService.canEditStudent">
                </mat-form-field>
                <div>
                    <button type="button" mat-button matStepperNext color="primary"
                        [disabled]="!appService.canEditStudent"
                        (click)="getAge(birthday.value)">Next</button>
                </div>
            </mat-step>
            <!--
                Student Name
            -->
            <mat-step [editable]="appService.canEditStudent" ngModelGroup="names" [stepControl]="f.controls.names">
                <ng-template matStepLabel>Student's Name</ng-template>
                <mat-checkbox *ngIf="age > 18" (click)="onSingleAccountCheck()">The person paying for the classes is also taking the classes. </mat-checkbox>
                <br *ngIf="age > 18">
                <mat-form-field>
                    <input type="text" matTooltip="This is the person that will be attending classes" matInput
                        placeholder="First Name" ngModel name="givenName" required
                        [disabled]="!appService.canEditStudent">
                </mat-form-field><br>
                <mat-form-field>
                    <input type="text" matTooltip="This is the person that will be attending classes"
                        matInput placeholder="Last Name" ngModel name="familyName" required
                        [disabled]="!appService.canEditStudent">
                </mat-form-field>
                <div>
                    <button type="button" mat-button matStepperNext color="primary"
                        [disabled]="!appService.canEditStudent">Next</button>
                </div>
            </mat-step>
            <!--
                Student's Informaton
            -->
            <mat-step [editable]="appService.canEditStudent" [stepControl]="f.controls.gender">
                <ng-template matStepLabel>Student's Information</ng-template>
                <mat-form-field>
                    <mat-label>Gender</mat-label>
                    <select matNativeControl ngModel name="gender" required [disabled]="!appService.canEditStudent">
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                    </select>
                </mat-form-field>
                <br>
                <mat-form-field *ngIf="age < 18">
                    <mat-label>School</mat-label>
                    <select matNativeControl ngModel name="school" required [disabled]="!appService.canEditStudent">
                        <option value="Preschool">Preschool</option>
                        <option value="Argyle Elementary">Argyle Elementary</option>
                        <option value="Campbell Middle School">Campbell Middle School</option>
                        <option value="King Springs Elementary">King Springs Elementary</option>
                        <option value="Nickajack Elementary">Nickajack Elementary</option>
                        <option value="Smyrna Elementary">Smyrna Elementary</option>
                        <option value="Teasley Elementary">Teasley Elementary</option>
                        <option value="Home">Home School</option>
                        <option value="Other">Other</option>
                    </select>
                </mat-form-field>
                <div>
                    <button type="submit" mat-raised-button matStepperNext color="primary"
                        [disabled]="!f.valid">Submit</button>
                </div>
            </mat-step>
        </mat-vertical-stepper>
    </form>
</mat-card>