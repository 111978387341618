<div class="pagetitle">
  <h1>Class Schedule</h1>
</div>
<div id="classes">
  <mat-card>
    <mat-card-content>
      <iframe id="schedule"
        src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FNew_York&amp;src=Y18xODhhdGJpZ3ZhdTk4ZzBlbnVubGdmaWVodHZqcTRnYWY1bm1zcHJqYzRuNnNwYmtAcmVzb3VyY2UuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%2350B68E&amp;mode=WEEK&amp;showTitle=0&amp;showNav=1&amp;showDate=1&amp;showTabs=1&amp;showCalendars=0&amp;showTz=0"
        style="border-width:0" width="800" height="600" frameborder="0" scrolling="no"></iframe>
    </mat-card-content>
  </mat-card>
</div>