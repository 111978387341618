import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { DialogComponent } from '../../layout/badges/dialog/dialog.component';

@Component({
	selector: 'app-mission',
	templateUrl: './mission.component.html',
	styleUrls: ['./mission.component.css'],
})
export class MissionComponent implements OnDestroy {
	private dialogSubscription!: Subscription;
	// These properties are public because they are used in the view
	public page = this.router.url;

	constructor(private router: Router, public appService: AppService, public dialog: MatDialog) {}

	public openMissionMessage(): void {
		this.appService.dialogMessage = `Here is an example of how you can learn more about our mission-critical policies 
		and practices.`;
		const dialogRef = this.dialog.open(DialogComponent);
		this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
			console.log(`Dialog result: ${result}`);
		});
	}

	ngOnDestroy(): void {
		if (this.dialogSubscription) this.dialogSubscription.unsubscribe();
	}
}
