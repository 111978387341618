import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-tabs',
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.css'],
})
export class TabsComponent {
	// These properties are public because they are used in the view
	@Input() tabLabels!: string[];
	public selected = new FormControl(0);

	constructor() {}

	public childComponentOutput(value: any): void {
		this.selected.setValue(value);
	}
}
