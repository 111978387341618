<div id="cover">
    <mat-card>
        <img mat-card-image src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2Ftaraharner.png?alt=media&token=436bd775-b154-4841-8550-1e3c4579b9fe" alt="Tara Harner">
    </mat-card>
</div>
<mat-divider></mat-divider>
<div id="tharner">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="profile-image"></div>
            <mat-card-title>Tara Harner</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p>Tara joined the Marines in 1993. During her training as an Avionics Technician, she was meritoriously promoted twice on her journey to Sergeant. She graduated from KSU in 2001 with a BS
                in Sociology/Criminology and a minor in Spanish.</p>
            <p>At the age of 40, Tara took her first Martial Arts class, and 3.5 years later, achieved the rank of Black Belt in CombatcMartial Arts, proving that it's never too late to start a new
                journey!</p>
            <p>For two years, Tara ran our D7 fitness program; during that time, Voyager Magazine published an article about the fitness program she was running.</p>
            <p>Tara spends much of her free time volunteering at local schools. She is frequently assisting with Career Day, Field Day, International Night, Bully Prevention, and STEM, just to name a
                few.</p>
            <p>She enjoys having a hand in instilling discipline and focus within the next generation. She loves the strong ties that she's making in the Smyrna community and looks forward to many
                more years here!</p>
        </mat-card-content>
    </mat-card>
    <mat-divider></mat-divider>
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="news-image"></div>
            <mat-card-title>VoyageATL Magazine</mat-card-title>
            <mat-card-subtitle>April 17, 2017</mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions style="margin:auto; text-align: center;">
            <button mat-raised-button color="primary" (click)="appService.openUrl('tara')">Read Article</button><br>
        </mat-card-actions>
    </mat-card>
</div>