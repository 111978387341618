import { LayoutModule } from '@angular/cdk/layout';
import { NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { firebase } from '../../environments/environment';
import { AppComponent } from '../app.component';
import { AboutComponent } from '../components/about/about.component';
import { BestOfCobbComponent } from '../components/about/bestofcobb/bestofcobb.component';
import { CoverComponent } from '../components/about/cover/cover.component';
import { ExpertiseComponent } from '../components/about/expertise/expertise.component';
import { FacebookComponent } from '../components/about/facebook/facebook.component';
import { GoogleComponent } from '../components/about/google/google.component';
import { MissionComponent } from '../components/about/mission/mission.component';
import { TopratedComponent } from '../components/about/toprated/toprated.component';
import { AliceComponent } from '../components/crew/alice/alice.component';
import { GregComponent } from '../components/crew/greg/greg.component';
import { MichaelComponent } from '../components/crew/michael/michael.component';
import { TaraComponent } from '../components/crew/tara/tara.component';
import { DialogComponent } from '../components/layout/badges/dialog/dialog.component';
import { FooterComponent } from '../components/layout/footer/footer.component';
import { LayoutComponent } from '../components/layout/layout.component';
import { SidenavComponent } from '../components/layout/sidenav/sidenav.component';
import { LoginComponent } from '../components/login/login.component';
import { ChampionsComponent } from '../components/more/champions/champions.component';
import { ContactComponent } from '../components/more/contact/contact.component';
import { HeroComponent } from '../components/more/hero/hero.component';
import { MaterialComponent } from '../components/more/material/material.component';
import { PieComponent } from '../components/more/pie/pie.component';
import { PrivacyComponent } from '../components/more/privacy/privacy.component';
import { SubscriptionComponent } from '../components/more/subscription/subscription.component';
import { TermsComponent } from '../components/more/terms/terms.component';
import { TigerStoriesComponent } from '../components/more/tigerstories/tigerstories.component';
import { AdultComponent } from '../components/programs/adult/adult.component';
import { CmaComponent } from '../components/programs/cma/cma.component';
import { KarateForKidsComponent } from '../components/programs/karateforkids/karateforkids.component';
import { TigersComponent } from '../components/programs/tigers/tigers.component';
import { YasComponent } from '../components/programs/yas/yas.component';
import { ClassesComponent } from '../components/schedules/classes/classes.component';
import { FightDirtyScheduleComponent } from '../components/schedules/fightdirtyschedule/fightdirtyschedule.component';
import { NinjaNightScheduleComponent } from '../components/schedules/ninjanightschedule/ninjanightschedule.component';
import { TournamentsComponent } from '../components/schedules/tournaments/tournaments.component';
import { FailureComponent } from '../components/trial/failure/failure.component';
import { RejectComponent } from '../components/trial/reject/reject.component';
import { SuccessComponent } from '../components/trial/success/success.component';
import { AccountComponent } from '../components/trial/tabs/account/account.component';
import { GetstartedComponent } from '../components/trial/tabs/getstarted/getstarted.component';
import { OrientationComponent } from '../components/trial/tabs/orientation/orientation.component';
import { PayComponent } from '../components/trial/tabs/pay/pay.component';
import { StudentComponent } from '../components/trial/tabs/student/student.component';
import { TabsComponent } from '../components/trial/tabs/tabs.component';
import { TrialOfferComponent } from '../components/trial/trialoffer.component';
import { MaterialModule } from './material.module';
import { AppRoutingModule } from './routing.module';

@NgModule({
	declarations: [
		AboutComponent,
		AccountComponent,
		AdultComponent,
		AliceComponent,
		AppComponent,
		BestOfCobbComponent,
		ChampionsComponent,
		ClassesComponent,
		CmaComponent,
		ContactComponent,
		CoverComponent,
		DialogComponent,
		ExpertiseComponent,
		FacebookComponent,
		FailureComponent,
		FightDirtyScheduleComponent,
		FooterComponent,
		GetstartedComponent,
		GoogleComponent,
		GregComponent,
		HeroComponent,
		KarateForKidsComponent,
		LayoutComponent,
		LoginComponent,
		MaterialComponent,
		MichaelComponent,
		MissionComponent,
		NinjaNightScheduleComponent,
		OrientationComponent,
		PayComponent,
		PieComponent,
		PrivacyComponent,
		RejectComponent,
		SidenavComponent,
		StudentComponent,
		SubscriptionComponent,
		SuccessComponent,
		TabsComponent,
		TaraComponent,
		TermsComponent,
		TigerStoriesComponent,
		TigersComponent,
		TopratedComponent,
		TournamentsComponent,
		TrialOfferComponent,
		YasComponent,
	],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		FlexLayoutModule,
		FormsModule,
		LayoutModule,
		MatButtonModule,
		MatIconModule,
		MatListModule,
		MatSidenavModule,
		MatToolbarModule,
		MaterialModule,
		ReactiveFormsModule,
		provideAuth(() => getAuth()),
		provideFirebaseApp(() => initializeApp(firebase.Cfg)),
		provideFirestore(() => getFirestore()),
		provideFunctions(() => getFunctions()),
		provideMessaging(() => getMessaging()),
		provideStorage(() => getStorage()),
	],
	providers: [],
	bootstrap: [AppComponent],
	entryComponents: [DialogComponent],
})
export class AppModule {}
