<div id="cover">
  <mat-card>
    <img mat-card-image src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2Fcmacover.png?alt=media&token=cf689e66-c9bf-4ad1-9501-62a17619866d" alt="Combat Martial Arts">
  </mat-card>
</div>
<div id="cma">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar class="explore-image"></div>
      <mat-card-title>There is no other mixed martial arts in Smyrna that compares to ours. Try it today! </mat-card-title>
    </mat-card-header>
    <!-- mat-card-content>Five Combat Martial Art classes for only $55. This exclusive web offer is the easiest way for you
      to try our martial arts classes!<br>
    </mat-card-content>
    <mat-card-actions >
      <button mat-raised-button color="primary" routerLink="/trial">Combat Martial Arts Offer</button><br>
    </mat-card-actions -->
  </mat-card>
</div>
<mat-divider></mat-divider>
<div id="contracts">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar class="contract-image"></div>
      <mat-card-title>No Contract and No Testing Fee!</mat-card-title>
    </mat-card-header>
    <mat-card-content>Unlike most other martial art schools, we do not require a contract. We also do not charge a color belt testing fee. </mat-card-content>
  </mat-card>
</div>
<mat-divider></mat-divider>
<div id="curriculum">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar class="school-image"></div>
      <mat-card-title>Curriculum</mat-card-title>
    </mat-card-header>
    <mat-card-content>Combat Martial Arts in Smyrna, GA is a “mixed martial arts” discipline based very heavily on the principles set forth by the Marine Corps Martial Arts Program (MCMAP), which
      trains Marines in hand-to-hand and close quarters combat at varying levels of proficiency. However, this program is not readily available for civilians to learn. CMA will use many of the core
      techniques of MCMAP with a special emphasis on its practicality for civilian use. Unlike more traditional martial arts, CMA will also have a focus on modern weapons training, including
      marksmanship training using air rifles and air pistols. The purpose of CMA is to develop students using the Warrior Ethos, which requires students to develop the mind, body and spirit
      simultaneously and equally (mental, physical and character development), including the responsible use of force, leadership, and teamwork. </mat-card-content>
  </mat-card>
</div>
<mat-divider></mat-divider>
<div id="instructors">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar class="ega-image"></div>
      <mat-card-title>Owners and Instructors</mat-card-title>
    </mat-card-header>
    <mat-card-content>Yongsa is owned by, and the Combat Cartial Arts classes are taught by former Marines. </mat-card-content>
  </mat-card>
</div>
<mat-divider></mat-divider>
<div id="schedule">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar class="calendar-image"></div>
      <mat-card-title>Class Schedule</mat-card-title>
    </mat-card-header>
    <mat-card-content>Click on the button below to view a complete schedule of Yongsa classes.</mat-card-content>
    <mat-card-actions style="margin:auto; text-align: center;">
      <button mat-raised-button color="primary" routerLink="/classes">Schedule</button><br>
    </mat-card-actions>
  </mat-card>
</div>
<mat-divider></mat-divider>
<div id="youtube">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar class="youtube-image"></div>
      <mat-card-title>MCMAP</mat-card-title>
      <mat-card-subtitle>We developed our Combat Martial Arts program with our Marine Corps training in mind. While we adapted the curriculum for civilian application, we thought you might enjoy this
        video that features the Marine Corp Martial Arts Program.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content id="youtube-content">
      <iframe width="700" height="401" src="https://www.youtube.com/embed/iM5plsDy2pk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </mat-card-content>
  </mat-card>
</div>