import { Component } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
	selector: 'app-michael',
	templateUrl: './michael.component.html',
	styleUrls: ['./michael.component.css'],
})
export class MichaelComponent {
	constructor(public appService: AppService) {}
}
