<mat-grid-list cols="2" rowHeight="2:1">
    <mat-grid-tile>
        <img class="responsive"
            src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2FState.png?alt=media&token=ed0dbe0c-465d-404a-94ac-3aa2b76f5108"
            alt="State">
    </mat-grid-tile>
    <mat-grid-tile><img class="responsive"
            src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2FDistrict.png?alt=media&token=cfa5bc2e-8892-4ff0-afb6-6c0e081564fe"
            alt="District">
    </mat-grid-tile>
</mat-grid-list>
<mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Start typing to filter" #input>
</mat-form-field>
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <!-- Name Column -->
    <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let champs"> {{champs.Name}} </td>
    </ng-container>
    <!-- Year Column -->
    <ng-container matColumnDef="Year">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Year </th>
        <td mat-cell *matCellDef="let champs"> {{champs.Year}} </td>
    </ng-container>
    <!-- Title Column -->
    <ng-container matColumnDef="Title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
        <td mat-cell *matCellDef="let champs"> {{champs.Title}} </td>
    </ng-container>
    <!-- Event Column -->
    <ng-container matColumnDef="Event">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Event </th>
        <td mat-cell *matCellDef="let champs"> {{champs.Event}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>