import { Injectable } from '@angular/core';
import { Contact } from '../models/app.model';

@Injectable({
	providedIn: 'root',
})
export class AppService {
	public dialogTitle = 'Mission Critical';
	public dialogSubtitle = 'Why it matters!';
	public dialogMessage = '';

	/***********************************************************************************************************************
	 * These properties are used in the forms for the trial offers
	 ***********************************************************************************************************************/
	public billingAccount!: Contact;
	public billingAccountId!: string;
	public canEditAccount = true;
	public canEditStudent = false;
	public canMakePayment = false;
	public hasExistingEmail!: boolean;
	public studentAccount!: Contact;
	public studentAccountId!: string;
	public studentBirthday!: string;

	constructor() {}

	public openUrl(page: any): void {
		switch (page) {
			case 'ataonline':
				window.open('https://www.ataonline.com/schedule_views', '_blank');
				break;
			case 'bestFriends':
				window.open('https://bestfriends.org/stories-blog-videos/latest-news/meet-taekwondo-centers-canine-cheerleader', '_blank');
				break;
			case 'bestofcobb':
				window.open('https://youtu.be/FZAukQAIY3w', '_blank');
				break;
			case 'campbell':
				window.open('https://campbellmiddleschool.rocks', '_blank');
				break;
			case 'cmaTrial':
				window.open('/trial', '_blank');
				break;
			case 'dog':
				window.open(
					'https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2FDontLettheDogsBiteActivityBook.pdf?alt=media&token=60282f74-3472-442e-8a46-f235495ab4c3',
					'_blank'
				);
				break;
			case 'dragon':
				window.open('http://www.atlantatournament.com/', '_blank');
				break;
			case 'facebook':
				window.open('https://www.facebook.com/pg/YongsaMartialArts/', '_blank');
				break;
			case 'facebookReviews':
				window.open('https://www.facebook.com/pg/YongsaMartialArts/reviews/', '_blank');
				break;
			case 'fallnationals':
				window.open('https://www.ataezsignup.com/tournament-profile-info/190/', '_blank');
				break;
			case 'home':
				window.open('https://g.page/YongsaMartialArts', '_blank');
				break;
			case 'material':
				window.open('https://sites.google.com/yongsa.net/practice/virtual-classes', '_blank');
				break;
			case 'michael':
				window.open('http://voyageatl.com/interview/meet-michael-harner-yongsa-martial-arts-smyrna/', '_blank');
				break;
			case 'niceville':
				window.open('https://www.ataezsignup.com/tournament-profile-info/281/', '_blank');
				break;
			case 'valdosta':
				window.open('https://www.ataezsignup.com/tournament-profile-info/316/', '_blank');
				break;
			case 'nickajack':
				window.open('https://nickajack.rocks', '_blank');
				break;
			case 'springnationals':
				window.open('https://www.ataonline.com/spring_nationals', '_blank');
				break;
			case 'taekwondoTrial':
				window.open('/trial', '_blank');
				break;
			case 'tara':
				window.open('http://voyageatl.com/interview/meet-tara-harner-of-d7-fitness-in-smyrna/', '_blank');
				break;
			case 'teasley':
				window.open('https://teasley.rocks', '_blank');
				break;
			case 'tigersTrial':
				window.open('/trial', '_blank');
				break;
			case 'topRated':
				window.open('https://www.topratedlocal.com/blog/awards/martial-arts-center/yongsa-martial-arts/2', '_blank');
				break;
			case 'virtual':
				window.open('https://www.ataonline.com/virtual_tournament', '_blank');
				break;
			default:
				break;
		}
	}
}
