<h1>INDEMNIFICATION</h1>
<p>
	You agree to defend, indemnify and hold harmless Yongsa Martial Arts and its affiliates from and against any and all
	claims, damages, costs and expenses, including attorneys' fees, arising from or related to your use of the Site or any
	breach by you of these Terms of Use.
</p>
<h1>TERMINATION</h1>
<p>
	These Terms of Use are effective unless and until terminated by either you or the Yongsa Martial Arts. You may
	terminate these Terms of Use at any time, provided that you discontinue any further use of this Site. Yongsa Martial
	Arts also may terminate these Terms of Use at any time and may do so immediately without notice, and accordingly deny
	you access to the Site, if in Yongsa Martial Arts's sole discretion you fail to comply with any term or provision of
	these Terms of Use. Upon any termination of these Terms of Use by either you or Yongsa Martial Arts, you must promptly
	destroy all materials downloaded or otherwise obtained from this Site, as well as all copies of such materials,
	whether made under the Terms of Use or otherwise.
</p>
<h1>GENERAL</h1>
<p>
	If any provision of these Terms of Use is held to be unenforceable for any reason, such provision shall be reformed
	only to the extent necessary to make it enforceable and the other terms of these Terms of Use shall remain in full
	force and effect. The failure of Yongsa Martial Arts to act with respect to a breach of these Terms of Use by you or
	others does not constitute a waiver and shall not limit Yongsa Martial Arts's rights with respect to such breach or
	any subsequent breaches. These Terms of Use shall be governed by and construed under Georgia law without regard to
	conflicts of law provisions. Any action or proceeding arising out of or related to these Terms of Use or your use of
	this Site must be brought in the state or federal courts of Arkansas and you consent to the exclusive personal
	jurisdiction of such courts.
</p>
