export const gapiSettings = {
	Cfg: {
		apiKey: 'AIzaSyCntgp0ELEZ8Uca1ypqpopo6yNRF9tQg20',
		clientId: '472191207902-llsr3b7ih5tmglh2l03mskpd22afk7g1.apps.googleusercontent.com',
		discoveryDocs: [
			'https://www.googleapis.com/discovery/v1/apis/people/v1/rest',
			'https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest',
			'https://content.googleapis.com/discovery/v1/apis/admin/directory_v1/rest',
		],
		scope:
			'https://www.googleapis.com/auth/contacts ' +
			'https://www.googleapis.com/auth/user.emails.read ' +
			'https://www.googleapis.com/auth/userinfo.email ' +
			'https://www.googleapis.com/auth/userinfo.profile ' +
			'https://www.googleapis.com/auth/calendar ' +
			'https://www.googleapis.com/auth/calendar.events ' +
			'https://www.google.com/m8/feeds/contacts ' +
			'https://www.googleapis.com/auth/admin.directory.group',
	},
	CalendarId: 'c_188atbigvau98g0enunlgfiehtvjq4gaf5nmsprjc4n6spbk@resource.calendar.google.com',
};
