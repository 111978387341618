import { Component } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
	selector: 'app-ninjanightschedule',
	templateUrl: './ninjanightschedule.component.html',
	styleUrls: ['./ninjanightschedule.component.css'],
})
export class NinjaNightScheduleComponent {
	constructor(public appService: AppService) {}
}
