<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" [mode]="(isHandset$ | async) ? 'side' : 'side'" [opened]="(isHandset$ | async) && !initialSidebar">
    <mat-toolbar color="primary"></mat-toolbar>
    <mat-nav-list>
      <a mat-list-item (click)="expandSideItemClick('about')">About</a>
      <a mat-list-item *ngIf="sideItem=='about'" (click)="onSideItemClick('/mission')"><span>Making Champions</span></a>
      <a mat-list-item *ngIf="sideItem=='about'" (click)="onSideItemClick('/bestofcobb')"><span>Best of Cobb</span></a>
      <a mat-list-item *ngIf="sideItem=='about'" (click)="onSideItemClick('/expertise')"><span>Expertise.com</span></a>
      <a mat-list-item *ngIf="sideItem=='about'" (click)="onSideItemClick('/facebook')"><span>Facebook</span></a>
      <a mat-list-item *ngIf="sideItem=='about'" (click)="onSideItemClick('/google')"><span>Google</span></a>
      <a mat-list-item *ngIf="sideItem=='about'" (click)="onSideItemClick('/toprated')"><span>Top Rated</span></a>
      <a mat-list-item (click)="expandSideItemClick('programs')">Programs</a>
      <a mat-list-item *ngIf="sideItem=='programs'" (click)="onSideItemClick('/tigers')"><span>Tigers</span></a>
      <a mat-list-item *ngIf="sideItem=='programs'" (click)="onSideItemClick('/karateforkids')"><span>Karate for Kids</span></a>
      <a mat-list-item *ngIf="sideItem=='programs'" (click)="onSideItemClick('/adult')"><span>Adult Taekwondo</span></a>
      <a mat-list-item *ngIf="sideItem=='programs'" (click)="onSideItemClick('/yas')"><span>Yongsa After School</span></a>
      <a mat-list-item (click)="expandSideItemClick('schedules')">Schedules</a>
      <a mat-list-item *ngIf="sideItem=='schedules'" (click)="onSideItemClick('/classes')"><span>Classes</span></a>
      <a mat-list-item *ngIf="sideItem=='schedules'" (click)="onSideItemClick('/tournaments')"><span>Tournaments</span></a>
      <a mat-list-item *ngIf="sideItem=='schedules'" (click)="onSideItemClick('/fightdirtyschedule')"><span>Fight Dirty</span></a>
      <a mat-list-item (click)="expandSideItemClick('crew')">Crew</a>
      <a mat-list-item *ngIf="sideItem=='crew'" (click)="onSideItemClick('/alice')"><span>Alice</span></a>
      <a mat-list-item *ngIf="sideItem=='crew'" (click)="onSideItemClick('/michael')"><span>Michael</span></a>
      <a mat-list-item *ngIf="sideItem=='crew'" (click)="onSideItemClick('/tara')"><span>Tara</span></a>
      <a mat-list-item *ngIf="sideItem=='crew'" (click)="onSideItemClick('/greg')"><span>Greg</span></a>
      <a mat-list-item (click)="expandSideItemClick('more')">More</a>
      <a mat-list-item *ngIf="sideItem=='more'" (click)="onSideItemClick('/material')"><span>Student Material</span></a>
      <a mat-list-item *ngIf="sideItem=='more'" (click)="onSideItemClick('/champs')"><span>Yongsa Champions</span></a>
      <a mat-list-item *ngIf="sideItem=='more'" (click)="onSideItemClick('/pie')"><span> Partnership in Education</span></a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button type="button" mat-icon-button (click)="onHamburgerClick()" *ngIf="isHandset$ | async">
        <mat-icon>menu</mat-icon>
      </button>
      <div *ngIf="!(isHandset$ | async)">
        <button mat-button [matMenuTriggerFor]="aboutMenu"><span class="menubar">About</span></button>
        <mat-menu #aboutMenu="matMenu" yPosition="below">
          <button mat-menu-item routerLink="/mission">Making Champions</button>
          <button mat-menu-item routerLink="/bestofcobb">Best of Cobb</button>
          <button mat-menu-item routerLink="/expertise">Expertise.com</button>
          <button mat-menu-item routerLink="/facebook">Facebook Reviews</button>
          <button mat-menu-item routerLink="/google">Google Reviews</button>
          <button mat-menu-item routerLink="/toprated">Top Rated</button>
        </mat-menu>
        <button mat-button [matMenuTriggerFor]="belowMenu"><span class="menubar">Programs</span></button>
        <mat-menu #belowMenu="matMenu" yPosition="below">
          <button mat-menu-item routerLink="/tigers">Tigers</button>
          <button mat-menu-item routerLink="/karateforkids">Karate for Kids</button>
          <button mat-menu-item routerLink="/adult">Adult Taekwondo</button>
          <button mat-menu-item routerLink="/yas">Yongsa After School</button>
        </mat-menu>
        <button mat-button [matMenuTriggerFor]="beforeMenu"><span class="menubar">Schedules</span></button>
        <mat-menu #beforeMenu="matMenu" yPosition="below">
          <button mat-menu-item routerLink="/classes">Classes</button>
          <button mat-menu-item routerLink="/tournaments">Tournaments</button>
          <button mat-menu-item routerLink="/fightdirtyschedule">Fight Dirty</button>
        </mat-menu>
        <button mat-button [matMenuTriggerFor]="afterMenu"><span class="menubar">Crew</span></button>
        <mat-menu #afterMenu="matMenu" yPosition="below">
          <button mat-menu-item routerLink="/alice">Alice</button>
          <button mat-menu-item routerLink="/michael">Michael</button>
          <button mat-menu-item routerLink="/tara">Tara</button>
          <button mat-menu-item routerLink="/greg">Greg</button>
        </mat-menu>
        <button mat-button [matMenuTriggerFor]="moreMenu"><span class="menubar">More</span></button>
        <mat-menu #moreMenu="matMenu" yPosition="below">
          <button mat-menu-item routerLink="/material">Student Material</button>
          <button mat-menu-item routerLink="/champs">Yongsa Champions</button>
          <button mat-menu-item routerLink="/pie">Partnership in Education</button>
        </mat-menu>
      </div>
      <div id='logo'>
        <span class="phone" *ngIf="!(isHandset$ | async)">678-381-8305</span>
        <a *ngIf="isLoggedIn" class="nav navbar-nav ml-auto nav-link" href="#" (click)="onLogoutClick()">Logout</a>
      </div>
    </mat-toolbar>
    <ng-content>
    </ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>