/***********************************************************************************************************************
 * This component displays pop up messages related to strategic topics concerning our mission, "Making Champions." It is
 * meant to give customers and potential customers a deeper understanding of our business principle, but in a way that
 * does not distract from the central website content.
 ***********************************************************************************************************************/
import { Component } from '@angular/core';
import { AppService } from '../../../../services/app.service';

@Component({
	selector: 'app-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.css'],
})
export class DialogComponent {
	constructor(public appService: AppService) {}
}
