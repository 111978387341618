import { Component } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
	selector: 'app-alice',
	templateUrl: './alice.component.html',
	styleUrls: ['./alice.component.css'],
})
export class AliceComponent {
	constructor(public appService: AppService) {}
}
