<div id="cover">
  <mat-card>
    <img mat-card-image src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2Ftigers.png?alt=media&token=e7d39bc0-410e-40b6-97a9-9dcd0d08d8f4" alt="Tiger in Tournament">
  </mat-card>
</div>
<div id="tigers">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar class="explore-image"></div>
      <mat-card-title>It Is Never Too Early Too Develop A Champion Spirit!</mat-card-title>
    </mat-card-header>
    <!-- mat-card-content>Five karate classes with uniform for only $55. This exclusive web offer is the easiest way for you
      to try our martial arts classes!<br>
    </mat-card-content>
    <mat-card-actions >
      <button mat-raised-button color="primary" routerLink="/trial">Tigers Trial Offer</button><br>
    </mat-card-actions -->
  </mat-card>
  <mat-divider></mat-divider>
</div>
<div id="prices">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar class="payment-image"></div>
      <mat-card-title>Transparent Pricing</mat-card-title>
    </mat-card-header>
    <mat-card-content>We offer no haggle transparent prices. Our base tuition is $145 per month.</mat-card-content>
  </mat-card>
  <mat-divider></mat-divider>
  <div id="contracts">
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar class="contract-image"></div>
        <mat-card-title>No Contract<a (click)="openContractMessage()">
            <mat-icon>info</mat-icon>
          </a></mat-card-title>
      </mat-card-header>
      <mat-card-content>For us, it is simple. You should attend class because you want to learn martial arts and not because you are stuck in a contract. Our policy of no contracts puts the burden on
        us as instructors to make sure that, during each class, we give you a reason to come back!</mat-card-content>
    </mat-card>
    <mat-divider></mat-divider>
    <div id="beltfees">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="hiddenfee-image"></div>
          <mat-card-title>No Testing Fee!<a (click)="openTestingMessage()">
              <mat-icon>info</mat-icon>
            </a></mat-card-title>
        </mat-card-header>
        <mat-card-content>Karate schools know that monthly tuition cost is a significant factor in deciding where to take classes. To hide the real cost of classes, many schools try to hide it in fees
          and purchases. A perfect example of this is belt testing fees. At Yongsa, we believe you earn your belt. You don't buy it. </mat-card-content>
      </mat-card>
    </div>
    <mat-divider></mat-divider>
    <div id="equipment">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="money-image"></div>
          <mat-card-title>Reasonable Equipment Cost!</mat-card-title>
        </mat-card-header>
        <mat-card-content>A net saving on month tuition becomes a net loss when you pay four times more for the required equipment and uniforms. At Yongsa, you will always pay a reasonable price for
          equipment and uniforms. We also buy back gently used gear and resell it at a deep discount. Most of the gear needed to train is purchase during enrollment at a price of $299.00 which also
          includes the first months tuition.</mat-card-content>
      </mat-card>
    </div>
    <mat-divider></mat-divider>
    <div id="age">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="childface-image"></div>
          <mat-card-title>Age Requirements</mat-card-title>
        </mat-card-header>
        <mat-card-content>Our ATA Tigers in Smyrna is a program specially designed for kids ages 4 - 6. </mat-card-content>
      </mat-card>
    </div>
    <mat-divider></mat-divider>
    <div id="curriculum">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="school-image"></div>
          <mat-card-title>Curriculum</mat-card-title>
        </mat-card-header>
        <mat-card-content>Parents want their child to learn how to focus, to develop self-discipline, and to develop self-confidence. However, at 4-6 years of age kids just want to play. Our Tigers
          curriculum is designed to accomplish both. Our instructors are masters at turning taekwondo training into games that keep kids engaged as they learn. Students are rewarded with stars they
          collect on their bag tag. When a student collects 25 stars they are given a new bag tag of a different color. As students get close to completing their current tag they get very excited
          about the prospect of getting a new "higher level" tag.</mat-card-content>
      </mat-card>
    </div>
    <mat-divider></mat-divider>
    <div id="characters">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="tigers-image"></div>
          <mat-card-title>ATA Tiger Characters</mat-card-title>
        </mat-card-header>
        <mat-card-content>We all know that Kids LOVE cartoons. To help keep students engage in the training, each week we feature a different <a href="" routerLink="/stories"> ATA Tiger character.</a>
          Students love learning about all the different characters each week. Each day we end class with the favorite game of that week's character. </mat-card-content>
        <mat-card-actions style="margin:auto; text-align: center;">
          <button mat-raised-button color="primary" routerLink="/stories">Tiger Stories</button><br>
        </mat-card-actions>
      </mat-card>
    </div>
    <mat-divider></mat-divider>
    <div id="schedule">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="calendar-image"></div>
          <mat-card-title>Class Schedule</mat-card-title>
        </mat-card-header>
        <mat-card-content>Click on the button below to view a complete schedule of Yongsa classes.</mat-card-content>
        <mat-card-actions style="margin:auto; text-align: center;">
          <button mat-raised-button color="primary" routerLink="/classes">Schedule</button><br>
        </mat-card-actions>
      </mat-card>
    </div>
    <mat-divider></mat-divider>
    <div id="youtube">
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="youtube-image"></div>
          <mat-card-title>Tigers On Youtube</mat-card-title>
        </mat-card-header>
        <mat-card-content id="youtube-content">
          <iframe width="700" height="401" src="https://www.youtube.com/embed/ZOIjBkc1pr4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </mat-card-content>
      </mat-card>
    </div>