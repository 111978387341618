<div id="cover">
    <mat-card>
        <img mat-card-image src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/lifeskills%2Fbelief.png?alt=media&token=ce4874bf-482d-4b90-a361-d583a77429e7" alt="Taekwondo">
    </mat-card>
</div>
<mat-card>
    <mat-card-header>
        <div mat-card-avatar class="infoicon"></div>
        <mat-card-title>Availability</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul>
            <li>We are currently offering our Yongsa After School (YAS) services to Teasley Elementary.</li>
            <li>We are looking to expand the program to other Smyrna area schools in the fall of 2022.</li>
        </ul>
    </mat-card-content>
</mat-card>
<mat-card>
    <mat-card-header>
        <div mat-card-avatar class="paymenticon"></div>
        <mat-card-title>Program Cost</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul>
            <li>Enrollment requires participation in a recurring payment program. We calculate tuition per month. The first eight classes in a month are $20 each, and every class after that is only
                $10.</li>
            <li>Yongsa Martial Arts must be informed at least two weeks before the regularly scheduled payment to pause or cancel enrollment in the program.</li>
        </ul>
    </mat-card-content>
</mat-card>
<mat-card>
    <mat-card-header>
        <div mat-card-avatar class="moneyicon"></div>
        <mat-card-title>Enrollment Cost</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul>
            <li>Enrollment - $399 one time cost includes:</li>
            <li>Two uniforms</li>
            <li>All sparring gear (mouthguard, headgear and face shield, chest protector, gloves, foot protectors)</li>
            <li>Four training weapons</li>
            <li>One rebreakable board</li>
            <li>One gear bag</li>
        </ul>
    </mat-card-content>
</mat-card>
<mat-card>
    <mat-card-header>
        <div mat-card-avatar class="warningicon"></div>
        <mat-card-title>Capacity</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul>
            <li>Program is limited to 11 students per day.</li>
            <li>Enrollment is on a first-come-first-serve basis.</li>
        </ul>
    </mat-card-content>
</mat-card>
<mat-card>
    <mat-card-header>
        <div mat-card-avatar class="transportationicon"></div>
        <mat-card-title>Transportation</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul>
            <li>Students will be transported from their school via 11 passenger van starting at 2:00 PM.</li>
            <li>Yongsa will maintain a list of all students who should be present during transportation.</li>
            <li>Students must wear seat belts during transportation.</li>
            <li>When a student checks into the class, we send an email to the billing account so that parents know their child arrived safely.</li>
            <li>Parents with a student in YAS have access to our security camera so they can observe their child's class. This camera is only available to staff and YAS parents.</li>
        </ul>
    </mat-card-content>
</mat-card>