<div id="cover">
    <mat-card>
        <img mat-card-image
            src="https://firebasestorage.googleapis.com/v0/b/yongsa.net/o/website%2Fgregharner.png?alt=media&token=34a765b0-8169-448a-b446-247e82e24ff9"
            alt="Greg Harner">
    </mat-card>
</div>
<mat-divider></mat-divider>
<div id="gharner">
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="profile-image"></div>
            <mat-card-title>Background</mat-card-title>
        </mat-card-header>
        <mat-card-content> Greg is a 3rd Degree Blackbelt, ATA Certified Instructor, and the ATA licensee for Yongsa. He
            is responsible for maintaining the accounting and business records and assisting with instruction. Greg
            started his martial arts training at 24 years old while serving in the Marines Corps in Memphis, TN. After
            leaving the Marine, Greg and his family moved to Atlanta where he graduated from Kennesaw State University
            with a Master of Business Administration in 2001. Greg resumed his taekwondo training in 2011 under Chief
            Master Arcemont of Kennesaw. </mat-card-content>
    </mat-card>
    <mat-divider></mat-divider>
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="book-image"></div>
            <mat-card-title>Certifications</mat-card-title>
        </mat-card-header>
        <mat-card-content> Greg still trains with Chief Master Arcemont in Kennesaw and regularly attends certification
            courses from the American Taekwondo Association. Greg's current certifications include: <mat-grid-list
                cols="2" rowHeight="8em">
                <mat-grid-tile colspan="1" rowspan="1">ATA Legacy Facilitator<br>Teaching ATA Tigers<br>Teaching ATA
                    Karate for Kid<br>Live Xtreme Level 1<br>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="1">Teaching ATA Leadership Sparring<br> ATA Judging Level 1
                    Corner<br> ATA Judging Level 2 Corner <br> ATA Judging Level 3 Center<br>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-card-content>
    </mat-card>
    <mat-divider></mat-divider>
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="medal-image"></div>
            <mat-card-title>Titles</mat-card-title>
        </mat-card-header>
        <mat-card-content> Greg Holds the following district titles: <mat-grid-list cols="2" rowHeight="8em">
                <mat-grid-tile colspan="1" rowspan="1"> Southeast District Champion in Xtreme Forms<br> Southeast
                    District Champion in Creative Forms<br> Southeast District Champion in Xtreme Weapons Forms<br>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="1">
                </mat-grid-tile>
            </mat-grid-list>
        </mat-card-content>
    </mat-card>
</div>